import PropTypes from "prop-types"
import React, { useRef, useState, useEffect } from "react"
import RecaptchaDisclosure from "components/Recaptcha/Disclosure"
import RecaptchaValidation from "components/Recaptcha/Validation"
import classNames from "classnames"
import PrivacyNotice from "components/NewsletterSubscriptionReactForm/PrivacyNotice"
import EmailField from "components/NewsletterSubscriptionReactForm/EmailField"
import i18n from "lib/i18n"
import { Button, ThemeProvider, accentTheme } from "@theconversation/ui"
import validateEmail from "lib/validateEmail"

import styles from "./styles.scss"

const SUBSCRIPTIONS_PATH = "/subscriptions"

/**
 * `NewsletterSubscriptionReactForm` duplicates `NewsletterSubscriptionForm` input and button,
 * but contains the entire form in it.
 */
export default function NewsletterSubscriptionReactForm({
  csrfToken,
  fullWidth,
  locale,
  location,
  newsletterListId,
  recaptcha,
  userEmail,
}) {
  const classes = [styles.container, fullWidth ? styles.fullWidth : styles.stacked]
  const [recaptchaToken, setRecaptchaToken] = useState()
  const [email, setEmail] = useState(userEmail)
  const [validEmail, setValidEmail] = useState(true)
  const formRef = useRef()
  const recaptchaRef = useRef()
  const [amplitudeDeviceId, setAmplitudeDeviceId] = useState("")
  const [submitting, setSubmitting] = useState(false)

  const onVerify = (token) => {
    setRecaptchaToken(token)
    formRef.current.submit()
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (!validateEmail(email)) {
      setValidEmail(false)
      return
    }

    if (window.Amplitude) {
      setAmplitudeDeviceId(window.Amplitude.getDeviceId())
    }

    // After (possibly) setting the Amplitude device ID above, we need React
    // to re-render the form with the updated value. Submitting the form here
    // doesn't allow that, so instead we set the submitting state and use a
    // useEffect() hook that will be performed after the re-render.
    setSubmitting(true)
  }

  useEffect(() => {
    if (submitting) {
      if (recaptcha.enabled) {
        recaptchaRef.current.execute()
      } else {
        formRef.current.submit()
      }
    }
  }, [submitting, recaptcha])

  return (
    <ThemeProvider theme={accentTheme()}>
      <form method="POST" action={SUBSCRIPTIONS_PATH} onSubmit={handleSubmit} ref={formRef}>
        <RecaptchaValidation
          enabled={recaptcha.enabled}
          instance={recaptchaRef}
          locale={locale}
          onVerify={onVerify}
          siteKey={recaptcha.siteKey}
        />

        <input type="hidden" name="authenticity_token" defaultValue={csrfToken} />
        <input type="hidden" name="subscribe[location]" defaultValue={location} />
        <input
          type="hidden"
          name="subscribe[newsletter_list_ids][]"
          defaultValue={newsletterListId}
        />
        <input type="hidden" name="subscribe[recaptcha_token]" defaultValue={recaptchaToken} />
        <input type="hidden" name="subscribe[amplitude_device_id]" value={amplitudeDeviceId} />

        <div className={classNames(classes)}>
          <EmailField
            defaultValue={userEmail}
            label={i18n.t("newsletters.labels.email")}
            fullWidth={fullWidth}
            name="subscribe[email]"
            className={styles.input}
            onChange={(e) => {
              setEmail(e.target.value)
              setValidEmail(true)
            }}
            error={!validEmail}
          />

          <RecaptchaDisclosure enabled={recaptcha.enabled} />

          <PrivacyNotice />

          <Button
            colour="primary"
            variant="contained"
            size="small"
            type="submit"
            className={styles.button}
          >
            {i18n.t("newsletters.buttons.subscribe")}
          </Button>
        </div>
      </form>
    </ThemeProvider>
  )
}

NewsletterSubscriptionReactForm.defaultProps = {
  fullWidth: false,
  userEmail: "",
}

NewsletterSubscriptionReactForm.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  newsletterListId: PropTypes.string.isRequired,
  userEmail: PropTypes.string,
  recaptcha: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    siteKey: PropTypes.string.isRequired,
  }).isRequired,
}
