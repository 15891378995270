// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes spin-0hRdy{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}.loader-WiwMC{animation:spin-0hRdy .8s infinite linear;display:block;width:12px;height:12px;margin:0 auto;border:3px solid #d9f3fc;border-top:3px solid #33bef2;border-radius:50%;transform-origin:50% 50%;transition:opacity 500ms}", "",{"version":3,"sources":["webpack://./app/assets/javascripts/components/Spinner/styles.scss"],"names":[],"mappings":"AAKA,sBACE,GACE,sBAAA,CAEF,KACE,wBAAA,CAAA,CAIJ,cACE,wCAAA,CACA,aAAA,CACA,UAfe,CAgBf,WAhBe,CAiBf,aAAA,CACA,wBAAA,CACA,4BAAA,CACA,iBAAA,CACA,wBAAA,CACA,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "loader-WiwMC",
	"spin": "spin-0hRdy"
};
export default ___CSS_LOADER_EXPORT___;
