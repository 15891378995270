import PropTypes from "prop-types"
import i18n from "lib/i18n"
import React from "react"
import classNames from "classnames"
import { Button, TextField } from "@theconversation/ui"
import styles from "./styles.scss"

export default function NewsletterSubscriptionForm({ fullWidth }) {
  const classes = [styles.container, fullWidth ? styles.fullWidth : styles.stacked]
  return (
    <div className={classNames(classes)}>
      <TextField
        label={i18n.t("newsletters.labels.email")}
        fullWidth={fullWidth}
        name="subscribe[email]"
        className={styles.input}
      />

      <Button
        colour="primary"
        variant="contained"
        size="small"
        type="submit"
        className={styles.button}
      >
        {i18n.t("newsletters.buttons.subscribe")}
      </Button>
    </div>
  )
}

NewsletterSubscriptionForm.defaultProps = {
  fullWidth: false,
}

NewsletterSubscriptionForm.propTypes = {
  fullWidth: PropTypes.bool,
}
