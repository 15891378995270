import i18n from "lib/i18n"
import { delay, template } from "lodash"
import { View } from "backbone"
import CalendarView from "views/calendar_view"
import CustomDateRangeConfirmationView from "views/custom_date_range_confirmation_view"
/* eslint-disable
    consistent-return,
    func-names,
    no-multi-assign,
    no-multi-str,
    no-return-assign,
    no-shadow,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let CalendarDateRangePickerView

// A nice, simple calendar date picker.
// Has two CalendarView objects for start and end dates
export default CalendarDateRangePickerView = (function () {
  CalendarDateRangePickerView = class CalendarDateRangePickerView extends View {
    static initClass() {
      this.prototype.className = "calendars"
      this.prototype.startOfTime = "2011-03-25"

      this.prototype.template = template(
        '\
<div class="calendar-container"></div>\
',
      )
    }

    initialize() {
      this.render()
    }

    render() {
      this.$el.html(this.template)
      this.calendarsEl = this.$el.find(".calendar-container")

      this.startCalendar = new CalendarView({
        rangeStart: this.startOfTime,
        rangeEnd: new Date(),
        message: i18n.t("calendar.label.select_start_date"),
      })
      this.startCalendar.$el.appendTo(this.calendarsEl)

      this.startCalendar.bind("update_date", (date) => {
        delay(() => this.displayEndDateCalendar(), 500)

        this.startCalendar.updateStartDate(date)
        this.endCalendar.updateRangeStartDate(date)
        this.endCalendar.updateStartDate(date)
        this.confirmationView.setStartDate(date)
        return this.setStartDate(date)
      })

      this.endCalendar = new CalendarView({
        rangeEnd: new Date(),
        message: i18n.t("calendar.label.select_end_date"),
      })
      this.endCalendar.$el.appendTo(this.calendarsEl)

      this.endCalendar.bind("update_date", (date) => {
        delay(() => this.displayConfirmationView(), 500)

        this.endCalendar.updateEndDate(date)
        this.confirmationView.setEndDate(date)
        return this.setEndDate(date)
      })

      this.confirmationView = new CustomDateRangeConfirmationView({
        startDate: this.startDate,
        endDate: this.endDate,
      })
      this.confirmationView.$el.appendTo(this.calendarsEl)

      // sad duplication, needs refactoring
      this.confirmationView.on("done", () => {
        this.trigger("update_date_range", this.startDate, this.endDate)
      })

      this.confirmationView.on("cancel", () => this.trigger("cancel_date_pick"))
    }

    setStartDate(date) {
      if (date !== this.startDate) {
        this.dirty = true
        return (this.startDate = date)
      }
    }

    setEndDate(date) {
      if (date !== this.endDate) {
        this.dirty = true
        return (this.endDate = date)
      }
    }

    displayStartDateCalendar() {
      return this.$el.addClass("open-start").removeClass("step-2").removeClass("step-3")
    }

    displayEndDateCalendar() {
      return this.$el.removeClass("open-start").addClass("step-2").removeClass("step-3")
    }

    displayConfirmationView() {
      return this.$el.removeClass("open-start").removeClass("step-2").addClass("step-3")
    }
  }
  CalendarDateRangePickerView.initClass()
  return CalendarDateRangePickerView
})()
