import EmailSelector from "components/Profile/Newsletters/EmailSelector"
import I18n from "lib/i18n"
import PropTypes from "prop-types"
import React from "react"
import Region from "models/profiles/newsletters/region"
import Spinner from "components/Spinner"
import Subscription from "models/profiles/newsletters/subscription"
import SubscriptionRow from "components/Profile/Newsletters/SubscriptionRow"
import User from "models/profiles/newsletters/user"
import getSharedClassNameGenerator from "lib/shared_class_name_generator"
import { ajax as $ajax } from "jquery"
import { partition } from "lodash"
import { ThemeProvider } from "@theconversation/ui"

import cx from "classnames"
import styles from "./styles.scss"

class ProfileNewslettersIndex extends React.Component {
  constructor(props) {
    super(props)

    this.onEmailChange = this.onEmailChange.bind(this)

    this.timerId = null

    this.state = {
      loaded: false,
      newsletterSubscriptions: [],
      user: this.props.user,
    }
  }

  componentWillMount() {
    this.fetchSubscriptions(this.state.user)
  }

  componentWillUpdate(nextProps, nextState) {
    if (!nextState.loaded) {
      this.timerId = setTimeout(() => this.fetchSubscriptions(nextState.user), 2000)
    }
  }

  onEmailChange(event) {
    clearTimeout(this.timerId)

    const newUser = new User(this.state.user)

    newUser.currentEmail = event.target.value

    this.fetchSubscriptions(newUser)
  }

  fetchSubscriptions(newUser) {
    const targetUrl = "/profiles/:profile_id/newsletters/subscriptions".replace(
      ":profile_id",
      newUser.slug,
    )

    $ajax({
      url: targetUrl,
      type: "GET",
      data: { email: newUser.currentEmail },
      success: (response) => {
        const finishedLoading = response.loaded
        const subscriptions = response.subscriptions.map((data) => new Subscription(data))

        this.setState({
          loaded: !!finishedLoading,
          newsletterSubscriptions: subscriptions,
          user: newUser,
        })
      },
    })
  }

  loadingSpinner() {
    if (this.state.loaded) {
      return null
    }

    return (
      <div className={cx("spinner-container", styles["spinner-container"])}>
        <Spinner loaded />
      </div>
    )
  }

  renderSubscriptionRows(subscriptions) {
    const currentEmail = this.state.user.currentEmail

    return subscriptions.map((newsletterSubscription) => {
      const subscriptionRowKey = [newsletterSubscription.listId, currentEmail].join("-")

      return (
        <SubscriptionRow
          key={subscriptionRowKey}
          newsletterSubscription={newsletterSubscription}
          user={this.state.user}
        />
      )
    })
  }

  renderInternationalNewsletterHeading(internationalSubscriptions) {
    // eslint-disable-line class-methods-use-this
    if (internationalSubscriptions.length === 0) {
      return null
    }

    return (
      <h2
        className={cx(
          "international-newsletters-heading",
          styles["international-newsletters-heading"],
        )}
      >
        {I18n.t("profile_newsletters.heading.international_newsletters")}
      </h2>
    )
  }

  render() {
    const currentRegion = this.props.currentRegion
    const [localSubscriptions, internationalSubscriptions] = partition(
      this.state.newsletterSubscriptions,
      (subscription) => subscription.region.code === currentRegion.code,
    )

    return (
      <ThemeProvider generateClassName={getSharedClassNameGenerator()}>
        <EmailSelector user={this.state.user} onEmailChange={this.onEmailChange} />

        {this.loadingSpinner()}

        <div className="local-newsletters">{this.renderSubscriptionRows(localSubscriptions)}</div>
        <div className="international-newsletters">
          {this.renderInternationalNewsletterHeading(internationalSubscriptions)}
          {this.renderSubscriptionRows(internationalSubscriptions)}
        </div>
      </ThemeProvider>
    )
  }
}

ProfileNewslettersIndex.propTypes = {
  currentRegion: PropTypes.instanceOf(Region).isRequired,
  user: PropTypes.instanceOf(User).isRequired,
}

export default ProfileNewslettersIndex
