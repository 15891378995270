/* eslint-disable
    no-multi-assign,
    no-return-assign,
    no-shadow,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import BasePage from "pages/base_page"

import SignupProcess from "views/signup_process"

let NewRegistrationPage

export default NewRegistrationPage = class NewRegistrationPage extends BasePage {
  initialize(options) {
    super.initialize(options)

    return (this.signup = new SignupProcess())
  }
}
