import i18n from "lib/i18n"
import { template } from "lodash"
import { View } from "backbone"
import ContactAuthorFormView from "views/contact_author_form_view"
import { isSessionActive } from "lib/currentUser/session"

/* eslint-disable
    class-methods-use-this,
    constructor-super,
    func-names,
    max-len,
    no-constant-condition,
    no-eval,
    no-multi-assign,
    no-shadow,
    no-this-before-super,
    no-undef,
    no-underscore-dangle,
    no-unused-expressions,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let ContactAuthorView

export default ContactAuthorView = (function () {
  ContactAuthorView = class ContactAuthorView extends View {
    static initClass() {
      this.prototype.events = { "click .profile-contact-button.signed-in": "_contactAuthor" }

      this.prototype._contactButtonTemplate = template(
        `\
<a class="profile-contact-button signed-in button primary large" href="#">
  <%- i18n.t('author_communication.button.contact') %>
</a>\
`,
        { imports: { i18n } },
      )

      this.prototype._signUpToContactButtonTemplate = template(
        `\
<a
  class="profile-contact-button sign-up-required button primary large"
  href="/sign_in?return_to=${window.location.pathname}"
>
  <%- i18n.t('author_communication.button.sign_in_to_contact') %>
</a>\
`,
        { imports: { i18n } },
      )
    }

    initialize(options) {
      super.initialize(options)

      this.render = this.render.bind(this)
      this._userBannedFromContactingAuthors = this._userBannedFromContactingAuthors.bind(this)
      this._userNotSignedIn = this._userNotSignedIn.bind(this)
      this._contactAuthor = this._contactAuthor.bind(this)

      this.user_id = options.user_id
      this.currentUserCanContactAuthors = options.currentUserCanContactAuthors
      this.contactable = options.contactable
      this.availableFor = options.availableFor

      return this.render()
    }

    render() {
      if (!this.contactable) {
        return
      }

      // Remove the invisible placeholder because we're about to render the
      // real button.
      this.$el.find(".contact-button-placeholder").remove()

      if (this._userNotSignedIn()) {
        this.$el.prepend(this._signUpToContactButtonTemplate())
      } else if (!this._userBannedFromContactingAuthors()) {
        this.$el.prepend(this._contactButtonTemplate())
      }

      super.render()
    }

    _userBannedFromContactingAuthors() {
      return !this.currentUserCanContactAuthors
    }

    _userNotSignedIn() {
      return !isSessionActive()
    }

    _contactAuthor(event) {
      event.preventDefault()
      new ContactAuthorFormView({
        author_id: this.user_id,
        availableFor: this.availableFor,
      }).$el.appendTo(document.body)
    }
  }
  ContactAuthorView.initClass()
  return ContactAuthorView
})()
