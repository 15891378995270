// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".selector-Cp5\\+s{margin-bottom:30px}.selector-Cp5\\+s label{float:left;font-weight:bold;margin-right:15px}", "",{"version":3,"sources":["webpack://./app/assets/javascripts/components/Profile/Newsletters/EmailSelector/styles.scss"],"names":[],"mappings":"AAAA,iBACE,kBAAA,CAEA,uBACE,UAAA,CACA,gBAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selector": "selector-Cp5+s"
};
export default ___CSS_LOADER_EXPORT___;
