import BasePage from "pages/base_page"
import i18n from "lib/i18n"
import React from "react"
import ReactDOM from "react-dom"
import { accentTheme, Button, ThemeProvider } from "@theconversation/ui"

import { NewsletterMultiSubscriptionForm } from "components/NewsletterMultiSubscriptionForm"

import { currentUser } from "lib/currentUser"

export default class NewsletterMultiSubscriptionPage extends BasePage {
  constructor(options) {
    super({
      ...options,
      fullWidth: options.fullWidth || false,
      recaptcha: TC.Config.recaptcha,
      locale: TC.locale,
    })
  }

  initialize(options) {
    super.initialize(options)
    this.renderSubscriptionForms(options)
  }

  async renderSubscriptionForms(options) {
    const { email } = await currentUser()
    const newsletterSubscriptionWrapper = this.$el.find(".subscription-newsletter-wrapper")[0]

    ReactDOM.render(
      <ThemeProvider theme={accentTheme()}>
        <NewsletterMultiSubscriptionForm
          {...newsletterSubscriptionWrapper.dataset}
          locale={options.locale}
          recaptcha={options.recaptcha}
          fullWidth={options.fullWidth}
          newsletters={options.newsletters}
          promoted={options.promoted}
          userEmail={email}
        />
      </ThemeProvider>,
      newsletterSubscriptionWrapper,
    )
  }
}
