// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".international-newsletters-heading-mcgSz{margin:40px 0 20px}.spinner-container-YTwmD{margin:40px 0;transform:scale(2)}@media(max-width: 480px){.spinner-container-YTwmD{margin:20px 0;transform:scale(1)}}", "",{"version":3,"sources":["webpack://./app/assets/javascripts/containers/ProfileNewslettersIndex/styles.scss"],"names":[],"mappings":"AAEA,yCACE,kBAAA,CAGF,yBACE,aAAA,CACA,kBAAA,CAGF,yBACE,yBACE,aAAA,CACA,kBAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"international-newsletters-heading": "international-newsletters-heading-mcgSz",
	"spinner-container": "spinner-container-YTwmD"
};
export default ___CSS_LOADER_EXPORT___;
