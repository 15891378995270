import PropTypes from "prop-types"
import React from "react"
import i18n from "lib/i18n"
import { TextField } from "@theconversation/ui"

export default function EmailField(props) {
  const { error } = props

  const errorMessage = error ? i18n.t("newsletters.errors.invalid_email") : ""

  return <TextField helperText={errorMessage} {...props} />
}

EmailField.propTypes = {
  error: PropTypes.bool.isRequired,
}
