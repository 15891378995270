import SuperLightboxView from "views/super_lightbox_view"
import CalendarDateRangePickerView from "views/calendar_date_range_picker_view"
/* eslint-disable
    no-multi-assign,
    no-shadow,
    no-underscore-dangle,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let DateSelectLightboxView

export default DateSelectLightboxView = class DateSelectLightboxView extends SuperLightboxView {
  initialize(options) {
    super.initialize(options)
    this.dateRangePicker = new CalendarDateRangePickerView({ ranges: [] })
    this.dateRangePicker.$el.appendTo(this.$el.find(".lightbox-inner"))

    this.listenTo(this.dateRangePicker, "cancel_date_pick", this._cancel)
    return this.listenTo(this.dateRangePicker, "update_date_range", this._update)
  }

  _cancel() {
    this.trigger("cancel")
    return this.close()
  }

  _update(start, end) {
    this.trigger("update", start, end)
    return this.close()
  }
}
