/* eslint-disable react/forbid-prop-types */

import i18n from "lib/i18n"
import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Dialog as UIDialog,
  DialogAction,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
  withStyles,
} from "@theconversation/ui"

const styles = (theme) => ({
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
})

/**
 * React component that'll render the trusted commenter dialog, which can be closed or confirmed.
 *
 * @param {String} userName name of the user we want to trust/untrust
 * @param {Object} classes injected JSS styles
 * @param {Function} closeModal function to handle closing the dialog
 * @param {Function} onSubmitDialog function to be called when dialog is submitted
 * @param {Boolean} open defines if the dialog is opened
 */
function Dialog({ classes, closeModal, onSubmitDialog, open, title }) {
  const [notes, setNotes] = useState()

  const handleDialogSubmit = (event) => {
    event.preventDefault()

    onSubmitDialog({ notes })
  }

  return (
    <UIDialog onClose={closeModal} open={open}>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} className={classes.title}>
            <Typography variant="h3">{title}</Typography>
          </Grid>

          <Grid item xs={12} className={classes.textField}>
            <TextField
              fullWidth
              label={i18n.t("profiles.labels.notes")}
              multiline
              name="notes"
              onChange={(event) => setNotes(event.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <DialogAction onClick={closeModal}>{i18n.t("profiles.button.cancel")}</DialogAction>

        <DialogAction onClick={handleDialogSubmit} variant="primary">
          {i18n.t("profiles.button.confirm")}
        </DialogAction>
      </DialogActions>
    </UIDialog>
  )
}

Dialog.defaultProps = {
  classes: {},
}

Dialog.propTypes = {
  classes: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  onSubmitDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
}

export default withStyles(styles)(Dialog)
