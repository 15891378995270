import $ from "jquery"
import { defer } from "lodash"
import { View } from "backbone"
/* eslint-disable
    consistent-return,
    constructor-super,
    no-constant-condition,
    no-eval,
    no-multi-assign,
    no-param-reassign,
    no-shadow,
    no-this-before-super,
    no-undef,
    no-underscore-dangle,
    no-unused-expressions,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Represents a toggleable menu for search constraints & faceting
// as represented in the markup by:
//
// <div id="search-facets">
//   <nav id="search-sort-selector" class="search-selector">
//     <div class="menu-button-contents">
//       Sort:
//       <span class="menu-button-text"></span>
//       <i class="icon-caret-down drop-down-caret"></i>
//     </div>
//
//     <ul class="menu">
//       <%= search_filter_radio_li 'sort', 'recency', t('search.filter.recency') %>
//       <%= search_filter_radio_li 'sort', 'relevancy', t('search.filter.relevance') %>
//     </ul>
//   </nav>
// </div>
//
let SearchFacetMenuView
export default SearchFacetMenuView = class SearchFacetMenuView extends View {
  initialize(options) {
    this._selectOption = this._selectOption.bind(this)
    this._documentClick = this._documentClick.bind(this)
    this._toggleMenu = this._toggleMenu.bind(this)
    // allow the user to toggle the menu open/close
    if (options == null) {
      options = {}
    }
    this.$el.on("click", this._toggleMenu)

    // find the already selected option from the DOM
    this.selectedOption = this.$el.find("li.checked")
    this.previouslySelectedOption = this.selectedOption

    // update .menu-button-text
    // .menu-button-text is the text which appears when the menu
    // is collapsed.
    //
    this._updateView()

    // *onSelect* is a literal of functions used to determine
    // what function to execute based on the option selected.
    //
    // should a key from the literal match the value of the option
    // selected then this function will be executed as aposed to
    // the default function.
    //
    this.onSelect = options.onSelect

    // apply the selectFacet handler for each of the
    // options in the menu.
    return this.$el.find("li").each((index, option) => $(option).on("click", this._selectOption))
  }

  restorePreviousOption() {
    this.selectedOption = this.previouslySelectedOption
    return this._updateView()
  }

  selectedValue() {
    return this.selectedOption.find("input").prop("value")
  }

  getSelectedLabel() {
    return this.selectedOption.find("label").text()
  }

  setMenuLabelText(text) {
    // menu-button-text
    // is the text which appears when the menu is collapsed
    // when the option selected changes this needs to be updated.
    return this.$el.find(".menu-button-text").text(text)
  }

  // *selectOption* handles the event when a user
  // clicks on an option in the menu
  //
  _selectOption(event) {
    // udate the selected option
    this.previouslySelectedOption = this.selectedOption
    this.selectedOption = $(event.currentTarget)

    if (this.selectedOption.hasClass("checked")) {
      // apply an onSelect handlers if they exist
      if (this.onSelect[this.selectedValue()]) {
        return this.onSelect[this.selectedValue()]()
      }
    } else {
      // update to newly selected option
      this._updateView()

      // execute onSelect handler
      if (this.onSelect[this.selectedValue()]) {
        return this.onSelect[this.selectedValue()]()
      }
      return this.onSelect.default()
    }
  }

  // *documentClick* handles the event when a user
  // clicks anywhere in the document, effectvely dismissing
  // the current menu
  //
  _documentClick(event) {
    event.preventDefault()
    return this._toggleMenu()
  }

  _toggleMenu(event) {
    if (event != null) {
      event.preventDefault()
    }
    this.$el.toggleClass("open")
    if (this.$el.hasClass("open")) {
      return defer(() => {
        this.$el.off("click", this._toggleMenu)
        return $(document).on("click", this._documentClick)
      })
    }
    return defer(() => {
      this.$el.on("click", this._toggleMenu)
      return $(document).off("click", this._documentClick)
    })
  }

  _updateView() {
    this.setMenuLabelText(this.getSelectedLabel())

    // updated all options
    this.$el.find("li").removeClass("checked")
    this.selectedOption.addClass("checked")

    // update the form element
    return this.selectedOption.find("input").prop("checked", true)
  }
}
