// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container-qCdhZ{align-items:stretch;display:flex;margin-bottom:20px;border-bottom:1px solid #62626a}.description-ZvRBN{padding:20px 0;padding-left:20px;padding-right:20px;width:65%}.description-region-GmhdN{font-size:16px;font-weight:normal}.description-frequency-HxObx{font-weight:700;margin-bottom:12px}.toggle-switch-fULNT{padding:20px 0;display:flex;width:15%}.toggle-switch-element-QPg47{align-self:center;margin:0 auto;transform:scale(1.4)}@media(min-width: 600px)and (max-width: 1465px){.description-ZvRBN{width:60%}.toggle-switch-fULNT{padding:0 5px;width:20%}}@media(max-width: 599px){.container-qCdhZ{flex-direction:column}.description-ZvRBN{width:90%}.toggle-switch-fULNT{width:100%}}", "",{"version":3,"sources":["webpack://./app/assets/javascripts/components/Profile/Newsletters/SubscriptionRow/styles.scss"],"names":[],"mappings":"AAOA,iBACE,mBAAA,CACA,YAAA,CACA,kBAAA,CACA,+BAAA,CAGF,mBAVE,cAAA,CAaA,iBAAA,CACA,kBAAA,CAEA,SAAA,CAGF,0BACE,cAAA,CACA,kBAAA,CAGF,6BACE,eAAA,CACA,kBAAA,CAGF,qBA7BE,cAAA,CAgCA,YAAA,CACA,SAAA,CAGF,6BACE,iBAAA,CACA,aAAA,CACA,oBAAA,CAGF,gDACE,mBACE,SAAA,CAGF,qBACE,aAAA,CACA,SAAA,CAAA,CAIJ,yBACE,iBACE,qBAAA,CAGF,mBACE,SAAA,CAGF,qBACE,UAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container-qCdhZ",
	"description": "description-ZvRBN",
	"description-region": "description-region-GmhdN",
	"description-frequency": "description-frequency-HxObx",
	"toggle-switch": "toggle-switch-fULNT",
	"toggle-switch-element": "toggle-switch-element-QPg47"
};
export default ___CSS_LOADER_EXPORT___;
