import cx from "classnames"
import I18n from "lib/i18n"
import PropTypes from "prop-types"
import React from "react"
import Subscription from "models/profiles/newsletters/subscription"
import User from "models/profiles/newsletters/user"
import { ajax as $ajax } from "jquery"
import { differenceInDays, parseISO } from "date-fns"

import styles from "./styles.scss"

class PendingConfirmationLink extends React.Component {
  constructor(props) {
    super(props)

    this.resendConfirmationEmail = this.resendConfirmationEmail.bind(this)

    this.state = {
      sentConfirmation: false,
    }
  }

  resendConfirmationEmail() {
    const newsletterSubscription = this.props.newsletterSubscription
    const user = this.props.user

    const targetUrl = "/profiles/:profile_id/newsletters/:list_id"
      .replace(":profile_id", user.slug)
      .replace(":list_id", newsletterSubscription.listId)

    $ajax({
      url: targetUrl,
      type: "POST",
      data: { email: user.currentEmail, subscribed: true },
      success: () => {
        this.setState({ sentConfirmation: true })
      },
    })
  }

  pendingConfirmationButton() {
    const newsletterSubscription = this.props.newsletterSubscription

    const resendConfirmationButtonText = I18n.t("profile_newsletters.buttons.resend_confirmation")
    const sentConfirmationText = I18n.t("profile_newsletters.subscriptions.confirmation_sent")

    const now = new Date()
    const subscribedAt = parseISO(newsletterSubscription.subscribedAt)

    if (this.state.sentConfirmation) {
      return <div>{sentConfirmationText}</div>
    }

    if (differenceInDays(now, subscribedAt) >= 1) {
      return (
        <button
          className={cx(styles["pending-confirmation-resend-button"], "button", "secondary")}
          onClick={this.resendConfirmationEmail}
        >
          {resendConfirmationButtonText}
        </button>
      )
    }

    return ""
  }

  render() {
    const pendingConfirmationText = I18n.t("profile_newsletters.subscriptions.pending_confirmation")

    return (
      <div className={styles["pending-confirmation-container"]}>
        {pendingConfirmationText}
        <div className={styles["pending-confirmation-message-container"]}>
          {this.pendingConfirmationButton()}
        </div>
      </div>
    )
  }
}

PendingConfirmationLink.propTypes = {
  newsletterSubscription: PropTypes.instanceOf(Subscription).isRequired,
  user: PropTypes.instanceOf(User).isRequired,
}

export default PendingConfirmationLink
