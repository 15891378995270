// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dialogOptions-POuJD .icon-Vb\\+2h{width:40px}.dialogOptions-POuJD .link-Ouj7N{line-height:40px;font-size:16px}", "",{"version":3,"sources":["webpack://./app/assets/javascripts/components/Podcast/Dialog/styles.scss"],"names":[],"mappings":"AAEI,kCACE,UAAA,CAGF,iCACE,gBAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogOptions": "dialogOptions-POuJD",
	"icon": "icon-Vb+2h",
	"link": "link-Ouj7N"
};
export default ___CSS_LOADER_EXPORT___;
