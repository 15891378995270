import $ from "jquery"
/* eslint-disable
    class-methods-use-this,
    consistent-return,
    constructor-super,
    max-len,
    no-constant-condition,
    no-eval,
    no-multi-assign,
    no-param-reassign,
    no-return-assign,
    no-shadow,
    no-this-before-super,
    no-undef,
    no-underscore-dangle,
    no-unused-expressions,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS001: Remove Babel/TypeScript constructor workaround
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import BasePage from "pages/base_page"

import SearchFacetMenuView from "views/search_facet_menu_view"
import DateSelectLightboxView from "views/date_select_lightbox_view"

let SearchPage

export default SearchPage = class SearchPage extends BasePage {
  initialize() {
    super.initialize()

    this._showCustomDateSelector = this._showCustomDateSelector.bind(this)
    this._defaultSelect = this._defaultSelect.bind(this)
    this._cancelCustomDate = this._cancelCustomDate.bind(this)
    this._setCustomDate = this._setCustomDate.bind(this)

    $("#search-facets").fadeIn()
    return $("#search-facets")
      .children()
      .each((index, facet) => {
        if (facet.id === "search-date-selector") {
          this.dateMenu = new SearchFacetMenuView({
            el: facet,
            onSelect: { default: this._defaultSelect, custom: this._showCustomDateSelector },
          })
          if (this.dateMenu.selectedValue() === "custom") {
            const start = $("#filter-date-custom-from").prop("value")
            const finish = $("#filter-date-custom-to").prop("value")
            return this._setCustomDateLabel(start, finish)
          }
        } else {
          return new SearchFacetMenuView({ el: facet, onSelect: { default: this._defaultSelect } })
        }
      })
  }

  _showCustomDateSelector(menu) {
    const lightBox = new DateSelectLightboxView()
    lightBox.$el.appendTo(this.$el)
    this.listenTo(lightBox, "update", this._setCustomDate)
    this.listenTo(lightBox, "cancel", this._cancelCustomDate)
    return this.listenTo(lightBox, "leave", this._cancelCustomDate)
  }

  _defaultSelect(event) {
    return this._submit()
  }

  _cancelCustomDate() {
    return this.dateMenu.restorePreviousOption()
  }

  _setCustomDate(start, finish) {
    this._setCustomDateLabel(start, finish)
    $("#filter-date-custom-from").prop("value", start)
    $("#filter-date-custom-to").prop("value", finish)
    return this._submit()
  }

  _setCustomDateLabel(start, finish) {
    const labelPrefix = this.dateMenu.getSelectedLabel()
    return this.dateMenu.setMenuLabelText(`${labelPrefix}: ${start} – ${finish}`)
  }

  _submit() {
    $("#search-results").fadeTo(250, 0.25)
    return $("#search_form").submit()
  }
}
