import $ from "jquery"
/* eslint-disable
    class-methods-use-this,
    consistent-return,
    default-case,
    func-names,
    no-multi-assign,
    no-shadow,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import BasePage from "pages/base_page"

let InstitutionPage

export default InstitutionPage = (function () {
  InstitutionPage = class InstitutionPage extends BasePage {
    static initClass() {
      this.prototype.events = { "click #tabbed-navigation a": "_clickHandheldTab" }
    }

    initialize(options) {
      super.initialize(options)

      this.institutionSlug = window.location.pathname.replace("/institutions/", "")
    }

    _clickHandheldTab(event) {
      event.preventDefault()
      const section = $(event.target).attr("href")
      $("#tabbed-navigation a.active").removeClass("active")
      $(event.target).addClass("active")
      switch (section) {
        case "#articles":
          $(".institution-page").removeClass("sm-display-contributors sm-display-jobs-and-events")
          return $(".institution-page").addClass("sm-display-articles")
        case "#contributors":
          $(".institution-page").removeClass("sm-display-articles sm-display-jobs-and-events")
          return $(".institution-page").addClass("sm-display-contributors")
        case "#jobs-and-events":
          $(".institution-page").removeClass("sm-display-articles sm-display-contributors")
          return $(".institution-page").addClass("sm-display-jobs-and-events")
      }
    }
  }
  InstitutionPage.initClass()
  return InstitutionPage
})()
