import React from "react"
import { Box, Checkbox, FormControlLabel, FormGroup } from "@theconversation/ui"
import { Link } from "@material-ui/core"
import i18n from "lib/i18n"
import PropTypes from "prop-types"

export function NewsletterSelector({ name, newsletter, selected, setSelected }) {
  return (
    <Box className="mb-2" data-testid="newsletter-selector">
      <FormGroup>
        <FormControlLabel
          value="end"
          control={
            <Checkbox
              name={name}
              value={newsletter.id}
              checked={selected[newsletter.id]}
              onChange={() => {
                setSelected({ ...selected, [newsletter.id]: !selected[newsletter.id] })
              }}
            />
          }
          label={<span className="font-bold">{newsletter.name}</span>}
        />
      </FormGroup>
      <p className="font-serif text-lg">
        {newsletter.blurb}
        {newsletter.preview_url && (
          <span>
            &nbsp;(
            <Link href={newsletter.preview_url} target="_blank">
              {i18n.t("newsletters_multi.buttons.sample")}
            </Link>
            )
          </span>
        )}
      </p>
      <p></p>
    </Box>
  )
}

NewsletterSelector.propTypes = {
  name: PropTypes.string.isRequired,
  newsletter: PropTypes.object.isRequired,
  selected: PropTypes.objectOf(PropTypes.bool).isRequired,
  setSelected: PropTypes.func.isRequired,
}
