import i18n from "lib/i18n"
import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import { Button, ThemeProvider } from "@theconversation/ui"
import getSharedClassNameGenerator from "lib/shared_class_name_generator"
import styles from "./styles.scss"

import PodcastDialog from "../../components/Podcast/Dialog"
import PodcastSubscriptionOption from "../../components/Podcast/SubscriptionOption"

class PodcastListenAndSubscribe extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isMobile: false,
      open: false,
    }

    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.updateViewportSize = this.updateViewportSize.bind(this)
  }

  componentDidMount() {
    this.updateViewportSize()
    window.addEventListener("resize", this.updateViewportSize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateViewportSize)
  }

  updateViewportSize() {
    this.setState({ isMobile: window.innerWidth <= 600 })
  }

  handleOpen() {
    this.setState({ open: true })
  }

  handleClose() {
    this.setState({ open: false })
  }

  renderSubscriptionOptions() {
    const options = Object.entries(this.props.subscriptionOptions).map((subscriptionOption) => {
      const [provider, url] = subscriptionOption
      return <PodcastSubscriptionOption key={provider} provider={provider} url={url} />
    })

    return options
  }

  renderPanel() {
    if (!this.state.isMobile) {
      return (
        <div className={styles.panel}>
          <h3>{i18n.t("podcast.label.listen_and_subscribe")}</h3>

          <ul>{this.renderSubscriptionOptions()}</ul>
        </div>
      )
    }

    return ""
  }

  renderButton() {
    if (this.state.isMobile) {
      return (
        <div className={styles.buttonContainer}>
          <Button className={styles.button} onClick={this.handleOpen}>
            {i18n.t("podcast.label.listen_and_subscribe")}
          </Button>
        </div>
      )
    }

    return ""
  }

  render() {
    return (
      <div className={classNames(styles.container, this.state.isMobile ? styles.isMobile : null)}>
        <ThemeProvider generateClassName={getSharedClassNameGenerator()}>
          {this.renderButton()}
          {this.renderPanel()}

          <PodcastDialog open={this.state.open} handleClose={this.handleClose}>
            <ul>{this.renderSubscriptionOptions()}</ul>
          </PodcastDialog>
        </ThemeProvider>
      </div>
    )
  }
}

PodcastListenAndSubscribe.propTypes = {
  subscriptionOptions: PropTypes.shape({
    googlepodcasts: PropTypes.string,
    itunes: PropTypes.string,
    pocketcasts: PropTypes.string,
    radiopublic: PropTypes.string,
    rss: PropTypes.string.isRequired,
    spotify: PropTypes.string,
    stitcher: PropTypes.string,
    tunein: PropTypes.string,
  }).isRequired,
}

PodcastListenAndSubscribe.defaultProps = {
  subscriptionOptions: () => {},
}

export default PodcastListenAndSubscribe
