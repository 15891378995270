// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subscriptionOption-5-XA\\+{list-style:none;padding-bottom:10px;clear:both}.icon-mC4Gg{float:left;margin-right:10px;width:20px}.link-P735Q{font-size:12px;color:#727272}.link-P735Q:hover{color:#262626}", "",{"version":3,"sources":["webpack://./app/assets/javascripts/components/Podcast/SubscriptionOption/styles.scss"],"names":[],"mappings":"AACE,2BAEE,eAAA,CACA,mBAAA,CACA,UAAA,CAGF,YACE,UAAA,CACA,iBAAA,CACA,UAAA,CAGF,YACE,cAAA,CACA,aAAA,CAEA,kBACE,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subscriptionOption": "subscriptionOption-5-XA+",
	"icon": "icon-mC4Gg",
	"link": "link-P735Q"
};
export default ___CSS_LOADER_EXPORT___;
