import $ from "jquery"
/* eslint-disable
    camelcase,
    consistent-return,
    func-names,
    guard-for-in,
    no-multi-assign,
    no-restricted-syntax,
    no-shadow,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import BasePage from "pages/base_page"

let NewEventPage

export default NewEventPage = (function () {
  NewEventPage = class NewEventPage extends BasePage {
    static initClass() {
      this.prototype.events = { "change #event_country": "changeCountry" }
    }

    initialize(options) {
      super.initialize(options)
      this.countrySelector = $("#event_country")
      this.stateSelector = $("#event_state")

      // Global doesn't have a default selected country
      // so lets disable the state selector until the user
      // selects a country for the event.
      if (!this.country()) {
        return this.disableStateSelector()
      }
    }

    changeCountry(event) {
      this.disableStateSelector()

      if (this.country()) {
        return this.updateStateSelector()
      }
      return this.clearStateSelector()
    }

    clearStateSelector() {
      return this.stateSelector.children("option").remove()
    }

    country() {
      return this.countrySelector.children("option:selected").text()
    }

    disableStateSelector() {
      return this.stateSelector.attr("disabled", true)
    }

    enableStateSelector() {
      return this.stateSelector.attr("disabled", false)
    }

    // request states for the selected country
    // and the populate the stateSelector with them.
    updateStateSelector() {
      const selected_state = $("#user_selected_event_state").val()
      return $.ajax({
        type: "GET",
        url: `/countries/${this.country()}/states`,
        dataType: "json",
        success: (data) => {
          // Trash all options
          this.clearStateSelector()
          this.enableStateSelector()

          return (() => {
            const result = []

            const blankOption = this.makeOption("", "", false)
            result.push(blankOption.appendTo(this.stateSelector))

            for (const stateCode in data) {
              // And put the new ones back in.
              const state = data[stateCode]
              const option = this.makeOption(stateCode, state.name, stateCode === selected_state)
              result.push(option.appendTo(this.stateSelector))
            }
            return result
          })()
        },
      })
    }

    makeOption(value, label, selected) {
      const option = $("<option>").attr({ value }).text(label)
      if (selected) {
        option.prop("selected", true)
      }
      return option
    }
  }
  NewEventPage.initClass()
  return NewEventPage
})()
