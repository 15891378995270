/* eslint-disable
    class-methods-use-this,
*/

import BasePage from "pages/base_page"
import React from "react"
import ReactDOM from "react-dom"
import PodcastListenAndSubscribe from "containers/PodcastListenAndSubscribe"

class PodcastShowPage extends BasePage {
  constructor(options) {
    super()

    this.initListenAndSubscribe(options)
  }

  initListenAndSubscribe(options) {
    if (document.getElementById("podcast-listen-and-subscribe")) {
      const domContainer = document.createElement("div")
      const subscriptionOptions = document.getElementById("podcast-listen-and-subscribe")
      subscriptionOptions.parentNode.insertBefore(domContainer, subscriptionOptions)

      const container = React.createElement(PodcastListenAndSubscribe, options)

      ReactDOM.render(container, domContainer)
    }
  }
}

export default PodcastShowPage
