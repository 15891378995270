import $ from "jquery"
/* eslint-disable
    class-methods-use-this,
    func-names,
    no-multi-assign,
    no-return-assign,
    no-shadow,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import BasePage from "pages/base_page"

let PodcastsPage

export default PodcastsPage = (function () {
  PodcastsPage = class PodcastsPage extends BasePage {
    static initClass() {
      this.prototype.events = { "change #podcast-sort": "changeSort" }
    }

    initialize(options) {
      return super.initialize(options)
    }

    changeSort() {
      const val = $("#podcast-sort").val()
      const url = `${document.location.pathname}?sort=${val}`

      return (document.location.href = url)
    }
  }
  PodcastsPage.initClass()
  return PodcastsPage
})()
