import { template } from "lodash"
import { View } from "backbone"
import autocomplete from "lib/autocomplete"
/* eslint-disable
    consistent-return,
    constructor-super,
    func-names,
    no-constant-condition,
    no-eval,
    no-multi-assign,
    no-shadow,
    no-this-before-super,
    no-undef,
    no-underscore-dangle,
    no-unused-expressions,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let ChooserView

// Works like chosen but with support for dynamic data.
//
// Emits "selected" event with item selected as the argument.
//
// If you are using a url as the source. Your endpoint should
// respond to GET with `term` used as the parameter for the search query.
//
// The server should respond with JSON containing a label and any
// other data required. For example:
//
// { label: "Item label", user: { id: 123, type: "editor" } }
//
// This JSON will be returned to you as a parameter of the "selected" event.
//
// Options:
//   source - Required. A url to query or existing collection of data
//   autoFocus - True/false, display suggestions on focus (default false)
//   limit - Number of suggestions to show, more than 10 scrolls (default 10)
//
// Usage (ajax):
//
// userChooserView = new ChooserView
//   placeholder: "This is the text that appears with nothing selected",
//   title: "This is the text label of a pre-selected thing",
//   source: "/user/search.json",
//   limit: 5
// userChooserView.$el.prependTo(someElement)
// userChooserView.on "selected", (item) =>
//   console.log(item.user.id, item.user.full_name)
//
// Usage (array):
//
// animalChooserView = new ChooserView
//   autoFocus: true
//   placeholder: "Select your favourite type of animal"
//   title: 'cats'
//   source: [
//     "cats",
//     "dogs",
//     "chickens",
//     "mice"
//   ]
// animalChooserView.$el.prependTo(someElement)
// animalChooserView.on "selected", (item) =>
//   console.log(item)
//
export default ChooserView = (function () {
  ChooserView = class ChooserView extends View {
    static initClass() {
      this.prototype.tagName = "div"
      this.prototype.className = "chooser-view"

      this.prototype.events = {
        click: "_handleClick",
        "click .clear": "_handleClear",
      }

      this.prototype.clearableButton = template(`\
<i class="icon-trash clear"></i>
    `)

      this.prototype.template = template(`\
<div class="chooser-view-selection-pane">
  <span class="chooser-view-title"><%- title %></span>
  <%= clearable %>
  <i class="icon-caret-down closed"></i>
  <i class="icon-caret-up opened"></i>
  <div class="chooser-view-autocomplete-wrapper">
    <i class="icon-search"></i>
    <input type="text" name="autocomplete">
  </div>
</div>\
`)
    }

    initialize(options) {
      super.initialize(options)
      this._handleClear = this._handleClear.bind(this)
      this._handleClick = this._handleClick.bind(this)
      this._handleSelect = this._handleSelect.bind(this)

      this.clearable = options.clearable
      this.placeholder = options.placeholder
      this.title = options.title

      this.render()

      this.inputEl = this.$el.find("input")
      this.inputEl.on("click", (event) => event.stopPropagation())
      return autocomplete(this.inputEl, {
        autoFocus: options.autoFocus,
        select: this._handleSelect,
        source: options.source,
        inline: true,
        limit: options.limit || 10,
        autoSelect: true,
      })
    }

    render() {
      super.render()
      const clearable = this.clearable ? this.clearableButton() : null
      const title = this.title || this.placeholder

      return this.$el.html(this.template({ clearable, title }))
    }

    setSource(source) {
      return this.inputEl.data("autocomplete").setSource(source)
    }

    reset() {
      this.title = undefined
      return this._updateTitle()
    }

    open() {
      this.$el.addClass("open")
      this.inputEl.val("").focus()
      this._updateTitle()
      return document.addEventListener("click", this._handleClick, true)
    }

    close() {
      this.$el.removeClass("open")
      this._updateTitle()
      return document.removeEventListener("click", this._handleClick, true)
    }

    _updateTitle() {
      return this.$el.find(".chooser-view-title").html(this.title || this.placeholder)
    }

    _handleClick(event) {
      event.stopPropagation()
      if (event.target === this.inputEl.get(0)) {
        return
      }

      if (this.$el.hasClass("open")) {
        return this.close()
      }
      return this.open()
    }

    _handleClear(event) {
      event.stopPropagation()
      this.reset()
      this.trigger("clear")
    }

    _handleSelect(event, ui) {
      if (typeof ui.item !== "undefined" && ui.item !== null) {
        this.title = ui.item.label || ui.item
        this.trigger("selected", ui.item)
      }
      return this.close()
    }
  }
  ChooserView.initClass()
  return ChooserView
})()
