import React, { useState } from "react"
import PropTypes from "prop-types"
import { ajax as $ajax } from "jquery"
import i18n from "lib/i18n"
import Flash from "lib/flash"
import { ThemeProvider } from "@theconversation/ui"

import Dialog from "./Dialog"

export default function ToggleTrustedCommenter({
  className,
  trustUserPath,
  trusted,
  untrustUserPath,
  userName,
}) {
  const [modalOpen, setModalOpen] = useState(false)
  const [isTrusted, setIsTrusted] = useState(trusted)

  const buttonCopy = isTrusted
    ? i18n.t("profiles.button.untrust_user")
    : i18n.t("profiles.button.trust_user")

  const dialogTitle = isTrusted
    ? i18n.t("profiles.headers.untrust_user_name", { name: userName })
    : i18n.t("profiles.headers.trust_user_name", { name: userName })

  const onSubmitDialog = ({ notes }) => {
    const requestPath = isTrusted ? untrustUserPath : trustUserPath
    const message = isTrusted
      ? i18n.t("profiles.messages.untrusted_user_as_commenter")
      : i18n.t("profiles.messages.trusted_user_as_commenter")

    $ajax({
      url: requestPath,
      method: "PUT",
      data: { notes },
      success: () => {
        setIsTrusted(!trusted)
        setModalOpen(false)
        Flash.now("notice", message)
      },
    })
  }

  return (
    <ThemeProvider>
      <button className={className} onClick={() => setModalOpen(true)}>
        {buttonCopy}
      </button>

      <Dialog
        closeModal={() => setModalOpen(false)}
        onSubmitDialog={onSubmitDialog}
        open={modalOpen}
        title={dialogTitle}
        userName={userName}
      />
    </ThemeProvider>
  )
}

ToggleTrustedCommenter.defaultProps = {
  className: "",
}

ToggleTrustedCommenter.propTypes = {
  className: PropTypes.string,
  trustUserPath: PropTypes.string.isRequired,
  trusted: PropTypes.bool.isRequired,
  untrustUserPath: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
}
