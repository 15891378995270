import Form from "components/Editorial/ToggleLockUser/Form"
import i18n from "lib/i18n"
import PropTypes from "prop-types"
import React from "react"

import {
  Dialog,
  DialogAction,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@theconversation/ui"

const DIALOG_CLASSES = { paper: "toggle-lock-dialog" }

export default class ToggleLockUserDialog extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)

    this.form = React.createRef()
  }

  handleSubmit(event) {
    this.form.current.submitForm(event)
  }

  render() {
    const { locked, onCancel, onSubmit, open, userId, userName } = this.props

    const toggleLockText = locked
      ? i18n.t("editorial_toggle_lock_user.action.unlock")
      : i18n.t("editorial_toggle_lock_user.action.lock")

    return (
      <Dialog classes={DIALOG_CLASSES} fullWidth onClose={onCancel} open={open}>
        <DialogTitle>
          {toggleLockText} &quot;{userName}&quot;
        </DialogTitle>

        <DialogContent>
          <Form locked={locked} onSubmit={onSubmit} ref={this.form} userId={userId} />
        </DialogContent>

        <DialogActions>
          <DialogAction onClick={onCancel}>
            {i18n.t("editorial_toggle_lock_user.buttons.cancel")}
          </DialogAction>

          <DialogAction onClick={this.handleSubmit} variant="primary">
            {i18n.t("editorial_toggle_lock_user.buttons.confirm")}
          </DialogAction>
        </DialogActions>
      </Dialog>
    )
  }
}

ToggleLockUserDialog.propTypes = {
  locked: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  userName: PropTypes.string.isRequired,
}
