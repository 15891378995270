import $ from "jquery"
// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
// Hack to set font family for Chrome/Windows to Helvetica (not Helvetica Neue)
// See http://web.archive.org/web/20111229203239/http://www.michaelhreed.com/2010/08/digg-and-chrome-css
// And http://www.hanselman.com/blog/InstallingHelveticaNeueFontsWithGoogleChromeOnWindowsConsideredHarmful.aspx

// XXX: This doesn't seem like it would be neccessary any more. If
// we really have to do this, it should use a CSS class.
if (navigator.platform.match("Win") && /WebKit/.test(navigator.userAgent)) {
  $(document.body).css("font-family", "helvetica, arial, sans-serif")
}
