import I18n from "lib/i18n"
import PendingConfirmation from "components/Profile/Newsletters/PendingConfirmationLink"
import PropTypes from "prop-types"
import React from "react"
import Subscription from "models/profiles/newsletters/subscription"
import User from "models/profiles/newsletters/user"
import { ajax as $ajax } from "jquery"
import { Switch } from "@theconversation/ui"

import cx from "classnames"
import styles from "./styles.scss"

class SubscriptionRow extends React.Component {
  constructor(props) {
    super(props)

    this.toggleSubscription = this.toggleSubscription.bind(this)

    this.state = {
      subscribed: props.newsletterSubscription.subscribed,
    }
  }

  toggleSubscription() {
    const isSubscribed = !this.state.subscribed
    const newsletterSubscription = this.props.newsletterSubscription
    const user = this.props.user
    const targetUrl = "/profiles/:profile_id/newsletters/:list_id"
      .replace(":profile_id", user.slug)
      .replace(":list_id", newsletterSubscription.listId)

    this.setState({ subscribed: isSubscribed })

    $ajax({
      url: targetUrl,
      type: "POST",
      data: { email: user.currentEmail, subscribed: isSubscribed },
    })
  }

  toggleSwitchElement() {
    const newsletterSubscription = this.props.newsletterSubscription
    const user = this.props.user

    if (!newsletterSubscription.confirmed) {
      return <PendingConfirmation newsletterSubscription={newsletterSubscription} user={user} />
    }

    return (
      <Switch
        checked={this.state.subscribed}
        colour="primary"
        disableRipple
        name="toggle-switch"
        onChange={this.toggleSubscription}
        value={newsletterSubscription.listId}
      />
    )
  }

  render() {
    const subscription = this.props.newsletterSubscription
    const region = subscription.region

    return (
      <div
        className={`newsletter-subscription-row ${styles.container} newsletter-${subscription.listId}`}
      >
        <div className={styles["toggle-switch"]}>
          <div className={styles["toggle-switch-element"]}>{this.toggleSwitchElement()}</div>
        </div>
        <div className={styles.description}>
          <h2 className={cx("newsletter-name")}>{subscription.name}</h2>
          <h3 className={cx("newsletter-region", styles["description-region"])}>
            {I18n.t("profile_newsletters.regions.the_conversation")} {region.name}
          </h3>
          <p className={cx("legacy newsletter-frequency", styles["description-frequency"])}>
            {subscription.frequency}
          </p>
          <div className={cx("newsletter-blurb", styles["description-blurb"])}>
            {subscription.blurb}
          </div>
        </div>
      </div>
    )
  }
}

SubscriptionRow.propTypes = {
  newsletterSubscription: PropTypes.instanceOf(Subscription).isRequired,
  user: PropTypes.instanceOf(User).isRequired,
}

export default SubscriptionRow
