/* eslint-disable
    func-names,
    no-multi-assign,
    no-shadow,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// this is a dumb model that maintains a list of Academic Titles we refer to
let AcademicTitles

export default AcademicTitles = (function () {
  AcademicTitles = class AcademicTitles {
    static initClass() {
      // List composed from the following Sources;
      // http://en.wikipedia.org/wiki/Academic_ranks_(Australia_and_New_Zealand)
      // http://en.wikipedia.org/wiki/List_of_academic_ranks#United_Kingdom
      // http://en.wikipedia.org/wiki/Academic_rank_in_the_United_States
      this.list = [
        "Adjunct assistant professor",
        "Adjunct associate",
        "Adjunct professor",
        "Assistant lecturer",
        "Assistant professor emeritus",
        "Assistant researcher",
        "Assistant scientist",
        "Associate dean",
        "Associate lecturer",
        "Associate professor",
        "Associate professor emeritus",
        "Associate research scientist",
        "Chair professor",
        "Chancellor",
        "Chief executive officer",
        "Clinical assistant professor",
        "Clinical associate professor",
        "Clinical professor",
        "Course coordinator",
        "Dean",
        "Dean of faculties",
        "Deputy Principal",
        "Deputy chancellor",
        "Deputy vice-chancellor",
        "Emeritus professor",
        "Emeritus reader",
        "Faculty dean",
        "Faculty deputy dean",
        "Fellow",
        "Head of department",
        "Head of school",
        "Instructor",
        "Junior scientist",
        "Lecturer",
        "Postdoctoral associate",
        "Postdoctoral fellow",
        "Postdoctoral research associate",
        "Postdoctoral research fellow",
        "Principal",
        "Pro-vice-chancellor",
        "Professor",
        "Professor emeritus",
        "Professorial fellow",
        "Program director",
        "Provost",
        "Reader",
        "Rector",
        "Registrar",
        "Research assistant",
        "Research assistant professor",
        "Research associate",
        "Research associate professor",
        "Research fellow",
        "Research officer",
        "Research professor",
        "Research scientist",
        "Senior fellow",
        "Senior lecturer",
        "Senior research associate",
        "Senior research fellow",
        "Senior research scientist",
        "Senior research technician",
        "Senior scientist",
        "Senior tutor",
        "Tutor",
        "Vice chancellor",
        "Vice-principal",
        "Visiting assistant professor",
        "Visiting associate professor",
        "Visiting professor",
        "Visitor",
      ]
    }
  }
  AcademicTitles.initClass()
  return AcademicTitles
})()
