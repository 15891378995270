// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container-oyXsm{width:100%;margin:0 0 .5rem}.container-oyXsm .button-Ojho1{padding:.5rem 2rem;height:40px}.fullWidth-VZgwS{display:flex;align-items:flex-end}.fullWidth-VZgwS .button-Ojho1{margin-left:1rem}.stacked-9-vr4{display:block}.stacked-9-vr4 .button-Ojho1{display:block;margin:1rem 0 2rem}.input-vrfGu input[type=text]{box-sizing:content-box;padding:.5rem 1rem;border:none}", "",{"version":3,"sources":["webpack://./app/assets/javascripts/components/NewsletterSubscriptionReactForm/styles.scss"],"names":[],"mappings":"AACE,iBACE,UAAA,CACA,gBAAA,CAEA,+BACE,kBAAA,CACA,WAAA,CAIJ,iBACE,YAAA,CACA,oBAAA,CAEA,+BACE,gBAAA,CAIJ,eACE,aAAA,CAEA,6BACE,aAAA,CACA,kBAAA,CAIJ,8BACE,sBAAA,CACA,kBAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container-oyXsm",
	"button": "button-Ojho1",
	"fullWidth": "fullWidth-VZgwS",
	"stacked": "stacked-9-vr4",
	"input": "input-vrfGu"
};
export default ___CSS_LOADER_EXPORT___;
