import i18n from "lib/i18n"
import $ from "jquery"
import { defer, each, keys, map, fromPairs, template } from "lodash"
import SuperLightboxView from "views/super_lightbox_view"
import Flash from "lib/flash"
import AuthorCommunication from "models/author_communication"
import refreshCSRFToken from "lib/refreshCSRFToken"
import { currentUser } from "lib/currentUser"

/* eslint-disable
    func-names,
    no-multi-assign,
    no-shadow,
    no-undef,
    no-underscore-dangle,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let ContactAuthorFormView

export default ContactAuthorFormView = (function () {
  ContactAuthorFormView = class ContactAuthorFormView extends SuperLightboxView {
    static initClass() {
      this.prototype.className = "contact-author lightbox clearfix"

      this.prototype.events = {
        "click footer a[data-action=cancel-attributions]": "_leave",
        "click a.submit": "_contactAuthor",
      }

      this.prototype.template = template(
        `\
<div id="contact-author">
  <h1 class="legacy page-header"><%- i18n.t('author_communication.label.contact_author') %></h1>

  <p class="ml-3"><%- i18n.t('author_communication.blurb.intro') %></p>

  <form class="formtastic newform">
    <fieldset class="inputs">
      <ol>
        <input id="to_author_id" type="hidden" name="to_author_id" value="<%- author_id %>">

        <li class="from_name string input">
          <label for="from_name">
            <%- i18n.t('author_communication.label.your_name') %>
          </label>

          <input
            id="from_name"
            name="from_name"
            type="text"
            autocomplete="off"
            placeholder="<%- i18n.t('author_communication.placeholders.your_full_name') %>"
            maxlength="255"
            disabled
            value="<%- fullName %>"
          >
        </li>

        <li class="from_email string input">
          <label for="from_email">
            <%- i18n.t('author_communication.label.your_email') %>
          </label>

          <input
            id="from_email"
            name="from_email"
            type="email"
            autocomplete="off"
            disabled
            placeholder="<%- i18n.t('author_communication.placeholders.your_email_address') %>"
            value="<%- email %>"
          >
        </li>

        <li class="reason-for-contacting select input">
          <label for="reason">
            <%- i18n.t('author_communication.label.reason_for_contacting') %>
          </label>

          <select name="reason">
            <% reasons.forEach(function(reason) {%>
              <option value="<%- reason['slug'] %>"><%- reason['label'] %></option>
            <% }); %>
          </select>
        </li>

        <li class="required text input">
          <label for="message">
            <%- i18n.t('author_communication.label.your_message') %>
          </label>

          <textarea
            id="message"
            name="message"
            rows="12"
            placeholder="<%- i18n.t('author_communication.placeholders.your_message') %>"
          ></textarea>
        </li>
      </ol>
    </fieldset>
  </form>
</div>

<footer class="button-row">
  <a data-action="cancel-attributions" class="button secondary" href="#">
      <%- i18n.t('author_communication.button.close') %>
    </a>

  <a class="submit button primary" href="#">
      <%- i18n.t('author_communication.button.contact') %>
    </a>
</footer>\
`,
        { imports: { i18n } },
      )
    }

    initialize(options) {
      super.initialize(options)
      this.author_id = options.author_id
      this.availableFor = options.availableFor
      refreshCSRFToken().then(() => this.render())
      this.emailEl = $("input[type=text]", this.el)

      return defer(() => this.emailEl.focus())
    }

    async render() {
      const { full_name: fullName, email } = await currentUser()

      $(this.innerEl).html(
        this.template({
          author_id: this.author_id,
          reasons: this.availableFor,
          fullName,
          email,
        }),
      )
      return super.render()
    }

    _attributesFromForm() {
      return fromPairs(
        map(this.$el.find("form").serializeArray(), ({ name, value }) => [name, value]),
      )
    }

    _contactAuthor(event) {
      event.preventDefault()

      this.communique = new AuthorCommunication()
      return this.communique.save(this._attributesFromForm(), {
        wait: true,
        success: (model, response, options) => {
          Flash.now("notice", i18n.t("author_communication.messages.success"))
          return this._leave()
        },
        error: (model, response, options) => {
          if (response.responseJSON && response.responseJSON.errors) {
            this._displayErrors(response.responseJSON.errors)
          }
          return Flash.now("alert", i18n.t("author_communication.messages.error"))
        },
      })
    }

    _displayErrors(errors) {
      this.$el.find("li.error").removeClass("error")
      this.$el.find(".inline-errors").remove()

      return each(keys(errors), (fieldName) => {
        const field = $(`#${fieldName}`)
        field.parents("li").addClass("error")
        return field.after(`<p class='inline-errors'>${errors[fieldName].join(", ")}</p>`)
      })
    }
  }
  ContactAuthorFormView.initClass()
  return ContactAuthorFormView
})()
