import BasePage from "pages/base_page"
import ProfileNewslettersIndex from "containers/ProfileNewslettersIndex"
import React from "react"
import ReactDOM from "react-dom"
import Region from "models/profiles/newsletters/region"
import User from "models/profiles/newsletters/user"

class ProfileNewslettersPage extends BasePage {
  constructor(options) {
    super()

    const domContainer = document.getElementById("profile-newsletters-index")

    const container = React.createElement(ProfileNewslettersIndex, {
      user: new User(options.user),
      currentRegion: new Region(options.currentRegion),
    })

    ReactDOM.render(container, domContainer)
  }
}

export default ProfileNewslettersPage
