import i18n from "lib/i18n"
import PropTypes from "prop-types"
import React from "react"
import { TextField } from "@theconversation/ui"

export default class Form extends React.Component {
  constructor(props) {
    super(props)

    this.submitForm = this.submitForm.bind(this)

    this.form = React.createRef()

    this.state = {
      valid: true,
    }
  }

  submitForm(event) {
    event.preventDefault()

    const form = this.form.current
    const reasonInput = form.elements.reason
    const isValid = reasonInput.value.trim() !== ""

    if (isValid) {
      this.props.onSubmit(reasonInput.value)
    } else {
      this.setState({ valid: false })
    }
  }

  render() {
    return (
      <form ref={this.form} onSubmit={this.submitForm}>
        <TextField
          autoFocus
          error={!this.state.valid}
          fullWidth
          id="reason"
          label={i18n.t("editorial_toggle_lock_user.labels.reason")}
          name="reason"
        />
      </form>
    )
  }
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
