/* eslint-disable
    constructor-super,
    func-names,
    no-constant-condition,
    no-eval,
    no-multi-assign,
    no-shadow,
    no-this-before-super,
    no-underscore-dangle,
    no-unused-expressions,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS001: Remove Babel/TypeScript constructor workaround
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import BasePage from "pages/base_page"
import initFlashNotices from "pages/content/initFlashNotices"

let ColumnPage

export default ColumnPage = (function () {
  ColumnPage = class ColumnPage extends BasePage {
    static initClass() {
      this.prototype.events = {
        "click .content-authors .content-authors-toggle": "_toggleColumnSidebar",
        "click .content-translations .content-translations-toggle": "_toggleTranslationSidebar",
      }
    }

    initialize(options) {
      super.initialize(options)

      this._toggleColumnSidebar = this._toggleColumnSidebar.bind(this)
      this._toggleTranslationSidebar = this._toggleTranslationSidebar.bind(this)

      initFlashNotices()
    }

    _toggleColumnSidebar(event) {
      event.preventDefault()
      return this.$el.find(".content-sidebar").toggleClass("open")
    }

    _toggleTranslationSidebar(event) {
      event.preventDefault()
      return this.$el.find(".content-translations").toggleClass("open")
    }
  }
  ColumnPage.initClass()
  return ColumnPage
})()
