import BasePage from "pages/base_page"
import confetti from "lib/confetti"

export default class EditorialArticleFinishedPage extends BasePage {
  initialize() {
    super.initialize()

    const origin = document.querySelector("article")
    confetti(origin)
  }
}
