// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container-ae8Fz{width:100%;margin:0 0 .5rem}.container-ae8Fz .button-35Vj2{padding:.5rem 2rem;height:40px}.fullWidth-wvbYD{display:flex;align-items:flex-end}.fullWidth-wvbYD .button-35Vj2{margin-left:1rem}.stacked-Gj8kX{display:block}.stacked-Gj8kX .button-35Vj2{display:block;margin:1rem 0 2rem}.input-t0fg6 input[type=email]{box-sizing:content-box;padding:.5rem 1rem;border:none}", "",{"version":3,"sources":["webpack://./app/assets/javascripts/components/NewsletterMultiSubscriptionForm/styles.scss"],"names":[],"mappings":"AACE,iBACE,UAAA,CACA,gBAAA,CAEA,+BACE,kBAAA,CACA,WAAA,CAIJ,iBACE,YAAA,CACA,oBAAA,CAEA,+BACE,gBAAA,CAIJ,eACE,aAAA,CAEA,6BACE,aAAA,CACA,kBAAA,CAIJ,+BACE,sBAAA,CACA,kBAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container-ae8Fz",
	"button": "button-35Vj2",
	"fullWidth": "fullWidth-wvbYD",
	"stacked": "stacked-Gj8kX",
	"input": "input-t0fg6"
};
export default ___CSS_LOADER_EXPORT___;
