import { View } from "backbone"
import { initializeCommonPageElements } from "pages/common"

// Legacy base class for pages.
//
// Every page on TC had a TC.page object which is an instance of a page class.
//
// That object was initialized in a :js_init content block, most pages would
// provide their own subclass with additional functionality, but basic pages
// just use this superclass.
//
// New pages should not extend BasePage, and instead just call the common
// page element initializer in pages/common:
//
//    import { initializeCommonPageElements } from "pages/common";
//
//    initializeCommonPageElements();
//
class BasePage extends View {
  initialize() {
    this.outerEl = this.$("#outer")
    this.topbar = this.$("#topbar")
    this.header = this.$("#header")
    this.footers = this.$("#footers")

    initializeCommonPageElements()
  }
}

// All pages are attached to the body
BasePage.prototype.el = document.body

export default BasePage
