// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pending-confirmation-container-wn4BC{text-align:center}.pending-confirmation-message-container-XZDnz{margin-top:10px}.pending-confirmation-resend-button-IGPaU{width:60%}", "",{"version":3,"sources":["webpack://./app/assets/javascripts/components/Profile/Newsletters/PendingConfirmationLink/styles.scss"],"names":[],"mappings":"AACE,sCACE,iBAAA,CAGF,8CACE,eAAA,CAGF,0CACE,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pending-confirmation-container": "pending-confirmation-container-wn4BC",
	"pending-confirmation-message-container": "pending-confirmation-message-container-XZDnz",
	"pending-confirmation-resend-button": "pending-confirmation-resend-button-IGPaU"
};
export default ___CSS_LOADER_EXPORT___;
