// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".disclosure-\\+VpdF{font-size:10px;margin-top:20px}", "",{"version":3,"sources":["webpack://./app/assets/javascripts/components/Recaptcha/Disclosure/styles.scss"],"names":[],"mappings":"AAAA,mBACE,cAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disclosure": "disclosure-+VpdF"
};
export default ___CSS_LOADER_EXPORT___;
