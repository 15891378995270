import Region from "models/profiles/newsletters/region"

class Subscription {
  constructor(attributes) {
    this.blurb = attributes.blurb
    this.confirmed = attributes.confirmed
    this.frequency = attributes.frequency
    this.listId = attributes.listId
    this.logoUrl = attributes.logoUrl
    this.name = attributes.name
    this.region = new Region(attributes.region)
    this.subscribed = attributes.subscribed
    this.subscribedAt = attributes.subscribedAt
  }
}

export default Subscription
