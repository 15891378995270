import { Model } from "backbone"
/* eslint-disable
    func-names,
    no-multi-assign,
    no-shadow,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let AuthorCommunication
export default AuthorCommunication = (function () {
  AuthorCommunication = class AuthorCommunication extends Model {
    static initClass() {
      this.prototype.urlRoot = "/author_communications"
    }
  }
  AuthorCommunication.initClass()
  return AuthorCommunication
})()
