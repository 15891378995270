// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container-J3olF{width:100%;margin:0 0 .5rem}.container-J3olF .button-qSn\\+F{padding:.5rem 2rem;height:40px}.fullWidth-Fz7QT{display:flex;align-items:flex-end}.fullWidth-Fz7QT .button-qSn\\+F{margin-left:1rem}.stacked-1a\\+Ff{display:block}.stacked-1a\\+Ff .button-qSn\\+F{display:block;margin:1rem 0 2rem}.input-h86c0 input[type=text]{box-sizing:content-box;padding:.5rem 1rem;border:none}", "",{"version":3,"sources":["webpack://./app/assets/javascripts/components/NewsletterSubscriptionForm/styles.scss"],"names":[],"mappings":"AACE,iBACE,UAAA,CACA,gBAAA,CAEA,gCACE,kBAAA,CACA,WAAA,CAIJ,iBACE,YAAA,CACA,oBAAA,CAEA,gCACE,gBAAA,CAIJ,gBACE,aAAA,CAEA,+BACE,aAAA,CACA,kBAAA,CAIJ,8BACE,sBAAA,CACA,kBAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container-J3olF",
	"button": "button-qSn+F",
	"fullWidth": "fullWidth-Fz7QT",
	"stacked": "stacked-1a+Ff",
	"input": "input-h86c0"
};
export default ___CSS_LOADER_EXPORT___;
