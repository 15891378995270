import PropTypes from "prop-types"
import React from "react"

const ICON_LOCK = "icon-lock"
const ICON_UNLOCK = "icon-unlock"

// For now, we won't use `@theconversation/ui/Button` here, since the editorial design hasn't been decided
const ToggleLockButton = ({ children, className, locked, onClick }) => {
  const iconClass = locked ? ICON_UNLOCK : ICON_LOCK

  return (
    <button className={className} onClick={onClick}>
      <i className={iconClass} />
      {children}
    </button>
  )
}

ToggleLockButton.defaultProps = {
  className: "",
}

ToggleLockButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  locked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default ToggleLockButton
