import i18n from "lib/i18n"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import getSharedClassNameGenerator from "lib/shared_class_name_generator"
import { ThemeProvider } from "@theconversation/ui"

import SubmitForm from "./SubmitForm"
import ToggleLockButton from "../../../components/Editorial/ToggleLockUser/Button"
import ToggleLockDialog from "../../../components/Editorial/ToggleLockUser/Dialog"

/* eslint-disable max-len */
/**
 * Component that will render a button to lock/unlock users. Clicking
 * on the button will then show a dialog to input the lock/unlock reason.
 *
 * You can opt out from showing the button and trigger the dialog manually by:
 * => passing `false` to the `showButton` prop
 * => controlling the dialog opened state through the `dialogOpen` prop
 *
 * @param {Function} [afterSubmit] callback function executed after the lock/unlock submit request
 * @param {String} [buttonClass] class name attribute for the button
 * @param {Function} [closeDialogCallback] callback function when the dialog closes. Useful when you want to control state
 * @param {Boolean} locked user is locked or not
 * @param {Boolean} [dialogOpen=false] determines if the dialog is opened
 * @param {Boolean} [showButton=true] shows or hide action button
 * @param {{action: String, category: String, label: String}} trackAction event to be submitted to Google Analytics
 * @param {Number, String} userId the user ID
 * @param {String} userName the user name
 */
/* eslint-enable max-len */
export default function ToggleLockUser({
  afterSubmit,
  buttonClass,
  closeDialogCallback,
  dialogOpen,
  locked,
  showButton,
  trackAction,
  userId,
  userName,
}) {
  const [userLocked, setUserLocked] = useState(locked)
  const [modalOpen, setModalOpen] = useState(dialogOpen)

  useEffect(() => setModalOpen(dialogOpen), [dialogOpen])

  const submitForm = (reason) => {
    new SubmitForm({
      locked: userLocked,
      reason,
      userId,
    }).submit((isLocked) => {
      afterSubmit(isLocked)
      setUserLocked(isLocked)
      closeDialogCallback()
      setModalOpen(false)
    })
  }

  const toggleLockText = userLocked
    ? i18n.t("editorial_toggle_lock_user.action.unlock")
    : i18n.t("editorial_toggle_lock_user.action.lock")

  return (
    <ThemeProvider generateClassName={getSharedClassNameGenerator()}>
      {showButton ? (
        <ToggleLockButton
          className={buttonClass}
          locked={userLocked}
          onClick={() => {
            setModalOpen(true)
          }}
        >
          {toggleLockText}
        </ToggleLockButton>
      ) : null}

      <ToggleLockDialog
        locked={userLocked}
        onCancel={() => {
          closeDialogCallback()
          setModalOpen(false)
        }}
        onSubmit={submitForm}
        open={modalOpen}
        userId={userId}
        userName={userName}
      />
    </ThemeProvider>
  )
}

ToggleLockUser.defaultProps = {
  afterSubmit: () => {},
  buttonClass: "",
  closeDialogCallback: () => {},
  dialogOpen: false,
  showButton: true,
  trackAction: {
    action: "Click",
    category: "Editorial",
    label: "User Lock",
  },
}

ToggleLockUser.propTypes = {
  afterSubmit: PropTypes.func,
  buttonClass: PropTypes.string,
  closeDialogCallback: PropTypes.func,
  dialogOpen: PropTypes.bool,
  locked: PropTypes.bool.isRequired,
  showButton: PropTypes.bool,
  trackAction: PropTypes.shape({
    action: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  userName: PropTypes.string.isRequired,
}
