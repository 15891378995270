import PropTypes from "prop-types"
import React from "react"
import Reaptcha from "reaptcha"

import styles from "./styles.scss"

export default function Validation({ enabled, instance, locale, onVerify, siteKey }) {
  if (!enabled) {
    return <React.Fragment />
  }

  return (
    <Reaptcha
      className={styles.grecaptchaBadge}
      hl={locale}
      onVerify={onVerify}
      ref={instance}
      sitekey={siteKey}
      size="invisible"
    />
  )
}

Validation.propTypes = {
  enabled: PropTypes.bool.isRequired,
  instance: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
    }),
  ]).isRequired,
  locale: PropTypes.string.isRequired,
  onVerify: PropTypes.func.isRequired,
  siteKey: PropTypes.string.isRequired,
}
