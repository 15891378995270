import ForgeryProtection from "lib/ForgeryProtection"

const CSRF_PARAM_META_TAG_NAME = "csrf-param"
const CSRF_TOKEN_META_TAG_NAME = "csrf-token"

/**
 * Assign a new CSRF token to the <meta name="csrf-token" /> tag's content attribute.
 * If the csrf-token meta tag and csrf-param don't exist, it will create them.
 *
 * @return {Promise}
 */
export default function refreshCSRFToken() {
  /**
   * @param {String} name
   * @param {String} content
   */
  const findOrReplaceMetaTag = (name, content) => {
    const tag = document.querySelector(`meta[name=${name}]`)

    if (tag === null) {
      const head = document.querySelector("head")
      const newMetaTag = document.createElement("meta")

      newMetaTag.setAttribute("name", name)
      newMetaTag.setAttribute("content", content)
      head.appendChild(newMetaTag)
    } else {
      tag.setAttribute("content", content)
    }
  }

  return ForgeryProtection.CSRF.then(({ param, token }) => {
    findOrReplaceMetaTag(CSRF_PARAM_META_TAG_NAME, param)
    findOrReplaceMetaTag(CSRF_TOKEN_META_TAG_NAME, token)
  })
}
