import i18n from "lib/i18n"
import { template } from "lodash"
import { View } from "backbone"
import { parse, format } from "date-fns"
import currentLocale from "lib/currentLocale"

const DATE_PARAM_FORMAT = "yyyy-MM-dd"
const DATE_DISPLAY_FORMAT = "MMMM do yyyy"

/* eslint-disable
    func-names,
    no-multi-assign,
    no-return-assign,
    no-shadow,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let CustomDateRangeConfirmationView
export default CustomDateRangeConfirmationView = (function () {
  CustomDateRangeConfirmationView = class CustomDateRangeConfirmationView extends View {
    static initClass() {
      this.prototype.className = "custom-date-range-confirmation-view"
      this.prototype.events = {
        "click .cancel": "_handleCancel",
        "click .done": "_handleDone",
      }

      this.prototype.template = template(
        `\
<div class="calendar-view-header">
  <a href="#" class="cancel"><%= i18n.t('calendar.button.cancel') %></a>
  <a href="#" class="done"><%= i18n.t('calendar.button.done') %></a>
</div>
<div class="selected-dates">
  <h2><%= i18n.t('calendar.label.ranges.starting_from') %></h2>
  <div class="start-date"><%- startDate %></div>

  <h2><%= i18n.t('calendar.label.ranges.ending_at') %></h2>
  <div class="end-date"><%- endDate %></div>
</div>\
`,
        { imports: { i18n } },
      )
    }

    initialize(options) {
      super.initialize(options)

      this.startDate = options.startDate
      this.endDate = options.endDate

      this.render()

      this.startDateEl = this.$el.find(".start-date")
      this.endDateEl = this.$el.find(".end-date")
    }

    render() {
      this.$el.html(
        this.template({
          startDate: this.startDate,
          endDate: this.endDate,
        }),
      )
    }

    /**
     * @param {String} date month, day and year formatted string. e.g.: "2021-06-17"
     */
    setStartDate(date) {
      const parsedDate = parse(date, DATE_PARAM_FORMAT, new Date())
      const formattedDate = format(parsedDate, DATE_DISPLAY_FORMAT, {
        locale: currentLocale.dateFnsLocale(),
      })

      this.startDateEl.html(formattedDate)
    }

    /**
     * @param {String} date month, day and year formatted string. e.g.: "2021-06-17"
     */
    setEndDate(date) {
      const parsedDate = parse(date, DATE_PARAM_FORMAT, new Date())
      const formattedDate = format(parsedDate, DATE_DISPLAY_FORMAT, {
        locale: currentLocale.dateFnsLocale(),
      })

      this.endDateEl.html(formattedDate)
    }

    _handleDone(event) {
      event.preventDefault()
      this.trigger("done")
    }

    _handleCancel(event) {
      event.preventDefault()
      this.trigger("cancel")
    }
  }
  CustomDateRangeConfirmationView.initClass()
  return CustomDateRangeConfirmationView
})()
