import i18n from "lib/i18n"
import PropTypes from "prop-types"
import React from "react"
import {
  Dialog,
  DialogAction,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@theconversation/ui"
import styles from "./styles.scss"

const PodcastDialog = ({ handleClose, open, children }) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>{i18n.t("podcast.label.select_podcast_app")}</DialogTitle>
    <DialogContent>
      <div className={styles.dialogOptions}>{children}</div>
    </DialogContent>
    <DialogActions>
      <DialogAction onClick={handleClose} variant="primary">
        {i18n.t("podcast.button.modal.close")}
      </DialogAction>
    </DialogActions>
  </Dialog>
)

PodcastDialog.defaultProps = {
  handleClose: () => {},
  open: false,
  children: undefined,
}

PodcastDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default PodcastDialog
