import BasePage from "pages/base_page"
import i18n from "lib/i18n"
import React from "react"
import ReactDOM from "react-dom"
import { accentTheme, Button, ThemeProvider } from "@theconversation/ui"

import NewsletterSubscriptionForm from "../components/NewsletterSubscriptionForm/"

export default class NewsletterSubscriptionPage extends BasePage {
  constructor(options) {
    super({
      events: { "click #newsletter-archive .button": "showMore" },
      fullWidth: options.fullWidth || false,
      recaptcha: TC.Config.recaptcha,
    })
  }

  initialize(options) {
    super.initialize(options)

    this.showMore = this.showMore.bind(this)

    this.renderSubscriptionForms(options)
    this.renderArchiveShowMoreButton()
  }

  renderSubscriptionForms(options) {
    const fields = this.$el.find(".subscription-newsletter-wrapper")
    Array.from(fields).forEach((field) => {
      ReactDOM.render(
        <ThemeProvider theme={accentTheme()}>
          <NewsletterSubscriptionForm fullWidth={options.fullWidth} recaptcha={options.recaptcha} />
        </ThemeProvider>,
        field,
      )
    })
  }

  showMore() {
    this.$el.find("#newsletter-archive .hidden").show()
    this.$el.find("#newsletter-archive .button").hide()
    this.$el.find("#newsletter-archive-show-more").hide()
  }

  renderArchiveShowMoreButton() {
    const newsletterArchive = this.$el.find("#newsletter-archive-show-more")
    if (newsletterArchive.length > 0) {
      ReactDOM.render(
        <ThemeProvider theme={accentTheme()}>
          <Button variant="outlined" size="small" type="submit" onClick={this.showMore}>
            {i18n.t("newsletters.buttons.show_more")}
          </Button>
        </ThemeProvider>,
        newsletterArchive[0],
      )
    }
  }
}
