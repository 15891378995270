import BasePage from "pages/base_page"

let NewSessionPage

export default NewSessionPage = (function () {
  NewSessionPage = class NewSessionPage extends BasePage {
    static initClass() {
      this.prototype.events = {
        "submit form#sign-in-form": "_formSubmit",
      }
    }

    _formSubmit(e) {
      e.preventDefault()

      if (window.Amplitude) {
        const amplitudeDeviceId = window.Amplitude.getDeviceId()
        this.$el.find("form#sign-in-form input[name*='amplitude_device_id']").val(amplitudeDeviceId)
      }

      e.target.submit()
    }
  }

  NewSessionPage.initClass()
  return NewSessionPage
})()
