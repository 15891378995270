import $ from "jquery"
/* eslint-disable
    class-methods-use-this,
    no-multi-assign,
    no-shadow,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import AcademicTitles from "lib/academic_titles"

import BasePage from "pages/base_page"
import autocomplete from "lib/autocomplete"

let ProfileCredentialsPage

export default ProfileCredentialsPage = class ProfileCredentialsPage extends BasePage {
  initialize(options) {
    super.initialize(options)

    return this.initAutoComplete()
  }

  initAutoComplete() {
    autocomplete($(".research-code.autocomplete"), {
      source: "/field_of_researches.json",
      minLength: 2,
      autoFocus: true,
      delay: 200,
      select(event, ui) {
        event.preventDefault()
        return $(this).val(ui.item.code)
      },
    })

    autocomplete($(".funding-source.autocomplete"), {
      source: "/funding_sources.json",
      minLength: 2,
      autoFocus: true,
      delay: 200,
      select(event, ui) {
        event.preventDefault()
        return $(this).val(ui.item.label)
      },
    })

    autocomplete($(".credential-title.autocomplete"), {
      source: AcademicTitles.list,
      minLength: 2,
      autoFocus: true,
      delay: 200,
      select(event, ui) {
        return this.val(ui.item)
      },
    })

    return autocomplete($(".professional-membership.autocomplete"), {
      source: "/professional_memberships.json",
      select(event, ui) {
        event.preventDefault()
        return $(this).val(ui.item.label)
      },
    })
  }
}
