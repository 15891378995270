import i18n from "lib/i18n"
import $ from "jquery"
import moment from "moment"
import { delay, template } from "lodash"
import { View } from "backbone"
/* eslint-disable
    class-methods-use-this,
    consistent-return,
    constructor-super,
    func-names,
    no-constant-condition,
    no-eval,
    no-multi-assign,
    no-plusplus,
    no-return-assign,
    no-shadow,
    no-this-before-super,
    no-undef,
    no-underscore-dangle,
    no-unused-expressions,
    no-unused-vars,
    one-var,
    radix,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let CalendarView
export default CalendarView = (function () {
  CalendarView = class CalendarView extends View {
    static initClass() {
      this.prototype.className = "calendar"

      this.prototype.events = {
        "click .previous-month": "_decrementCalendarMonth",
        "click .next-month": "_incrementCalendarMonth",
        "click .day a": "daySelect",
        "change select[name='month']": "_setCalendarMonth",
        "change select[name='year']": "_setCalendarYear",
      }

      this.prototype.startOfTime = "2011-03-25"

      this.prototype.template = template(`\
<div class="calendar-view-header">
  <span><%- message %></span>
</div>
<div class="months">
  <a href="#" class="previous-month"><i class="icon-caret-left"></i></a>
  <p class="selected-date">
    <span class="month">
      <select name="month"></select>
    </span>
    <span class="year">
      <select name="year"></select>
    </span>
  </p>
  <a href="#" class="next-month"><i class="icon-caret-right"></i></a>
</div>\
`)

      this.prototype.monthData = template(
        `\
<ol class="days">
  <li><%= i18n.t('calendar.label.day_abbreviation.sunday') %></li>
  <li><%= i18n.t('calendar.label.day_abbreviation.monday') %></li>
  <li><%= i18n.t('calendar.label.day_abbreviation.tuesday') %></li>
  <li><%= i18n.t('calendar.label.day_abbreviation.wednesday') %></li>
  <li><%= i18n.t('calendar.label.day_abbreviation.thursday') %></li>
  <li><%= i18n.t('calendar.label.day_abbreviation.friday') %></li>
  <li><%= i18n.t('calendar.label.day_abbreviation.saturday') %></li>
</ol>
<% weeks.forEach(function(week, index) {%>
  <ol class="week">
    <% week.forEach(function(day, index) {%>
      <% if (day && day !== "") { %>
        <%
          dayClass = "day ";
          if (day.isAfter(startDate) && day.isBefore(endDate)) {
            dayClass += " within-range";
          }

          if (day.isBefore(rangeStart) || day.isAfter(rangeEnd)) {
            dayClass += " out-of-range";
          }
        %>

        <li class="<%- dayClass %>">
          <span><a href="#" data-date="<%- day.format("YYYY-MM-DD") %>">
             <%- day.format("D") %></span>
          </a>
        </li>

      <% } else { %>
        <li class="empty">
          &nbsp;
        </li>
        <% } %>
      </li>
    <% }); %>
  </ol>
<% }); %>\
`,
        { imports: { i18n } },
      )
    }

    initialize(options) {
      this.render = this.render.bind(this)
      this.daySelect = this.daySelect.bind(this)
      this._setMonthSelectOptions = this._setMonthSelectOptions.bind(this)
      this._fillYearSelect = this._fillYearSelect.bind(this)
      this.startDate = options.startDate
      this.calendarMoment = moment(options.displayDate)
      this.endDate = options.endDate

      this.rangeStart = options.rangeStart
      this.rangeEnd = options.rangeEnd

      this.allowableDateRange = {
        start: moment(this.rangeStart),
        end: moment(this.rangeEnd),
      }

      this.message = options.message

      return this.render()
    }

    render() {
      this.$el.html(
        this.template({
          month: this.calendarMoment.format("MMMM, YYYY"),
          message: this.message,
        }),
      )

      this.monthsEl = this.$el.find(".months")
      this.monthDataEl = $("<div class='month-data'></div>")
      this.$el.append(this.monthDataEl)

      this.renderMonthSelector()
      this.renderMonthData()

      this.monthSelectEl = this.$el.find("select[name='month']")
      this.yearSelectEl = this.$el.find("select[name='year']")

      return this._refreshCalendarInterface(this.allowableDateRange)
    }

    renderMonthSelector() {
      this.previousMonth = this.monthsEl.find(".previous-month")
      this.nextMonth = this.monthsEl.find(".next-month")

      if (this.calendarMoment.format("YYYY-MM") === moment(this.startOfTime).format("YYYY-MM")) {
        this.previousMonth.addClass("current")
      } else {
        this.previousMonth.removeClass("current")
      }

      if (this.calendarMoment.format("YYYY-MM") === moment(this.rangeStart).format("YYYY-MM")) {
        this.previousMonth.addClass("current")
      } else {
        this.previousMonth.removeClass("current")
      }

      if (this.calendarMoment.format("YYYY-MM") === moment(this.rangeEnd).format("YYYY-MM")) {
        return this.nextMonth.addClass("current")
      }
      return this.nextMonth.removeClass("current")
    }

    renderMonthData() {
      return this.monthDataEl.html(
        this.monthData({
          weeks: this._weekDataForCalendarMonth(
            this.calendarMoment,
            this.rangeStart,
            this.rangeEnd,
          ),
          rangeStart: this.rangeStart,
          rangeEnd: this.rangeEnd,
          startDate: this.startDate,
          endDate: this.endDate,
        }),
      )
    }

    _setCalendarMonth(event) {
      event.preventDefault()
      this.calendarMoment.month(parseInt($(event.target).val()))
      return this.transitionMonth()
    }

    _setCalendarYear(event) {
      event.preventDefault()
      this.calendarMoment.year($(event.target).val())
      return this.transitionMonth()
    }

    _decrementCalendarMonth(event) {
      event.preventDefault()
      this.calendarMoment.subtract(1, "months")
      return this.transitionMonth()
    }

    _incrementCalendarMonth(event) {
      event.preventDefault()
      this.calendarMoment.add("months", 1)
      return this.transitionMonth()
    }

    _refreshCalendarInterface() {
      this._fillYearSelect(this.allowableDateRange)
      this._setMonthSelectOptions(this.calendarMoment, this.allowableDateRange)
      this.monthSelectEl.val(`${this.calendarMoment.month()}`)
      return this.yearSelectEl.val(`${this.calendarMoment.year()}`)
    }

    _constrainCalendarMomentToAllowableRange() {
      this.calendarMoment = moment.max(this.allowableDateRange.start, this.calendarMoment).clone()
      return (this.calendarMoment = moment
        .min(this.allowableDateRange.end, this.calendarMoment)
        .clone())
    }

    transitionMonth() {
      this._constrainCalendarMomentToAllowableRange()
      this._refreshCalendarInterface()
      this.monthDataEl.remove()

      this.renderMonthSelector()

      this.monthDataEl = $("<div class='month-data'></div>")
      this.$el.append(this.monthDataEl)
      return this.renderMonthData()
    }

    _weekDataForCalendarMonth(displayDate, startDate, endDate) {
      const weeks = []
      const startOfMonth = moment(displayDate).startOf("month")
      const endOfMonth = moment(displayDate).endOf("month")

      const weekIterator = moment(displayDate).startOf("month")

      weeks.push(this._paddedDaysOfWeek(weekIterator, endOfMonth, startOfMonth))
      while (weekIterator.isBefore(endOfMonth)) {
        weekIterator.add("weeks", 1)
        weeks.push(this._paddedDaysOfWeek(weekIterator, endOfMonth, startOfMonth))
      }

      return weeks
    }

    _paddedDaysOfWeek(startOfWeek, endOfMonth, startOfMonth) {
      const days = []

      const dayIterator = startOfWeek.clone()

      // Set day iterator to the first day in the week
      // so we can pad the results if necessary
      dayIterator.subtract(dayIterator.day(), "days")

      while (days.length < 7) {
        if (dayIterator.isBefore(startOfMonth) || dayIterator.isAfter(endOfMonth)) {
          days.push("")
        } else {
          days.push(dayIterator.clone())
        }

        dayIterator.add("days", 1)
      }

      return days
    }

    // only re-render when start date changes
    // so we can limit end date selection
    updateRangeStartDate(date) {
      this.rangeStart = date
      this.allowableDateRange = {
        start: moment(this.rangeStart),
        end: moment(this.rangeEnd),
      }
      if (moment(date).isAfter(this.calendarMoment)) {
        this.calendarMoment = moment(date)
      }
      return delay(this.render, 250)
    }

    updateStartDate(date) {
      return (this.startDate = date)
    }

    updateEndDate(date) {
      return (this.endDate = date)
    }

    daySelect(event) {
      event.preventDefault()
      if (!$(event.currentTarget).closest(".out-of-range").length) {
        this.$(".selected").removeClass("selected-day")

        const day = $(event.currentTarget)
        day.closest("li").addClass("selected-day")

        const selectedDate = day.data("date")
        return this.trigger("update_date", selectedDate)
      }
    }

    _setMonthSelectOptions(selectedDate, allowableDateRange) {
      let monthIterator, monthLimit
      if (moment(selectedDate).year() === allowableDateRange.start.year()) {
        monthIterator = allowableDateRange.start.month()
      } else {
        monthIterator = 0
      }

      if (moment(selectedDate).year() === allowableDateRange.end.year()) {
        monthLimit = allowableDateRange.end.month()
      } else {
        monthLimit = 11
      }

      let optionsMarkup = ""

      while (monthIterator <= monthLimit) {
        const currentMoment = moment().month(monthIterator)
        optionsMarkup += `<option value="${currentMoment.month()}">${currentMoment.format(
          "MMMM",
        )}</option>`
        monthIterator++
      }

      return this.monthSelectEl.html(optionsMarkup)
    }

    _fillYearSelect(allowableDateRange) {
      let year = allowableDateRange.start.year()
      const endYear = allowableDateRange.end.year()

      let optionsMarkup = ""

      while (year <= endYear) {
        optionsMarkup += `<option value="${year}">${year}</option>`
        year++
      }

      return this.yearSelectEl.html(optionsMarkup)
    }
  }
  CalendarView.initClass()
  return CalendarView
})()
