import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import i18n from "lib/i18n"
import { TextField } from "@theconversation/ui"
import validateEmail from "lib/validateEmail"

export function EmailField({ userEmail, handleValidEmail, errorMessage, ...remainingProps }) {
  const [error, setError] = useState(false)
  const [email, setEmail] = useState(userEmail)

  useEffect(() => {
    const validEmail = validateEmail(email)
    setError(!validEmail)
    handleValidEmail(validEmail)
  }, [email])

  return (
    <TextField
      helperText={error ? errorMessage : ""}
      type="email"
      onChange={(e) => {
        setEmail(e.target.value)
      }}
      value={email}
      {...remainingProps}
    />
  )
}

EmailField.propTypes = {
  handleValidEmail: PropTypes.func.isRequired,
  userEmail: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
}
