import $ from "jquery"
/* eslint-disable
    class-methods-use-this,
    consistent-return,
    constructor-super,
    func-names,
    no-constant-condition,
    no-eval,
    no-multi-assign,
    no-return-assign,
    no-shadow,
    no-this-before-super,
    no-underscore-dangle,
    no-unused-expressions,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS001: Remove Babel/TypeScript constructor workaround
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import ChooserView from "views/chooser_view"

import autocomplete from "lib/autocomplete"
import AcademicTitles from "lib/academic_titles"
import BasePage from "pages/base_page"

let EditProfilePage

export default EditProfilePage = (function () {
  EditProfilePage = class EditProfilePage extends BasePage {
    static initClass() {
      this.prototype.events = {
        "change li.file.input input": "showPhoto",
        "click li.choice input.other-radio-option": "chooseOther",
        "change li.choice .other-option-label input[type=radio]": "focusOtherInput",
      }
    }

    initialize(options) {
      super.initialize(options)
      this._crop = this._crop.bind(this)
      this.autocompleteInstitution()
      this.institutionSelect = $("#profile_form_institution")
      return this.autocompleteJobTitle()
    }

    showPhoto(event) {
      const originalImage = $(".avatar > img") // this should probably be passed in from elsewhere
      const input = $(event.currentTarget).get(0)

      if (this._fileReaderExists() && this._inputHasFiles(input)) {
        const reader = new FileReader()
        reader.onload = (event) => {
          // Create a temporary image object
          const img = new Image()
          img.onload = (event) => this._crop(originalImage, event.currentTarget)

          // Set the source of the temporary image to the base64 data of selected image, this fires onload
          return (img.src = event.target.result)
        }

        return reader.readAsDataURL(input.files[0])
      }
    }

    chooseOther(event) {
      const input = $(event.currentTarget)
      // From the text field, get the radio button
      const radio = input.siblings("label.other-radio-option").find("input[type=radio]")

      // Uncheck all radio buttons, and check the one we want
      const allRadioButtons = $(`input[name='${radio.attr("name")}']`)
      allRadioButtons.prop("checked", false)
      return radio.prop("checked", true)
    }

    focusOtherInput(event) {
      const radio = $(event.currentTarget)
      // From the radio button, find the text field and give focus.
      const input = radio.parent().siblings(".other-radio-option")
      return input.focus()
    }

    autocompleteInstitution() {
      const institutionSelect = $("#profile_form_institution")

      const institutionChooser = new ChooserView({
        title: institutionSelect.data("label"),
        placeholder: "Select an institution",
        source: "/institutions/search.json",
        clearable: true,
      })

      institutionSelect.after(institutionChooser.$el)
      institutionChooser.on("clear", () => institutionSelect.val(""))
      return institutionChooser.on("selected", (item) => institutionSelect.val(item.institution.id))
    }

    autocompleteJobTitle() {
      return autocomplete($(".occupation.autocomplete"), {
        source: AcademicTitles.list,
        minLength: 2,
        autoFocus: true,
        delay: 200,
        select(event, ui) {
          return this.val(ui.item)
        },
      })
    }

    _fileReaderExists() {
      return typeof FileReader !== "undefined"
    }

    _inputHasFiles(input) {
      return input.files && input.files[0]
    }

    _getCroppedImageElementFor(originalImage) {
      const container = originalImage.parent()
      const croppedImage = container.find(".replacedAvatar")
      // If there already is a replacement Image div, use that, else append one to the container
      if (croppedImage.length > 0) {
        return croppedImage
      }
      container.append("<div class='replacedAvatar'></div>")
      return container.find(".replacedAvatar")
    }

    _crop(originalImage, newImage) {
      const croppedImage = this._getCroppedImageElementFor(originalImage)
      croppedImage.hide()
      croppedImage.css("background", `url(${newImage.src}) no-repeat center center`)

      // Set the cropping similar to what would happen server-side
      croppedImage.css("background-size", "cover")
      croppedImage.width(originalImage.width())
      croppedImage.height(originalImage.height())
      originalImage.hide()
      return croppedImage.fadeIn()
    }
  }
  EditProfilePage.initClass()
  return EditProfilePage
})()
