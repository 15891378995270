export const TOP_REMOVAL_REASON = ["trolling", "off_topic", "misinformation"]
export const REMOVAL_REASONS = [
  "personal_abuse",
  "disrespectful",
  "unconstructive",
  "legal_reasons",
  "fake_name",
  "part_of_a_thread",
  "spam",
  "other",
]
