import PropTypes from "prop-types"
import React from "react"
import User from "models/profiles/newsletters/user"

import styles from "./styles.scss"

class EmailSelector extends React.Component {
  constructor(props) {
    super(props)

    this.onEmailChange = this.props.onEmailChange.bind(this)
  }

  render() {
    const user = this.props.user
    const options = user.emails.map((email) => (
      <option key={email} value={email}>
        {email}
      </option>
    ))

    return (
      <div className={`email-selector ${styles.selector}`}>
        <label htmlFor="email">Email</label>
        <select
          name="email"
          id="email"
          defaultValue={user.currentEmail}
          onChange={this.onEmailChange}
        >
          {options}
        </select>
      </div>
    )
  }
}

EmailSelector.propTypes = {
  onEmailChange: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(User).isRequired,
}

export default EmailSelector
