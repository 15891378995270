import BasePage from "pages/base_page"
import i18n from "lib/i18n"
import React from "react"
import ReactDOM from "react-dom"
import { accentTheme, Button, ThemeProvider } from "@theconversation/ui"

import NewsletterSubscriptionReactForm from "components/NewsletterSubscriptionReactForm"

/**
 * This page is similar to `pages/newsletter_subscription_show_page.jsx`, but it uses the
 * `NewsletterSubscriptionReactForm`, assuming its props from the `.subscription-newsletter-wrapper`
 * tag dataset.
 *
 * This page also doesn't have compatibility with the newsletter subscription ERB form.
 */
export default class NewsletterSubscriptionReactPage extends BasePage {
  constructor() {
    super({
      events: { "click #newsletter-archive .button": "showMore" },
      recaptcha: TC.Config.recaptcha,
      locale: TC.locale,
    })
  }

  initialize(options) {
    super.initialize(options)

    this.showMore = this.showMore.bind(this)

    this.renderSubscriptionForms(options)
    this.renderArchiveShowMoreButton()
  }

  renderSubscriptionForms(options) {
    const newsletterSubscriptionWrapper = this.$el.find(".subscription-newsletter-wrapper")[0]
    ReactDOM.render(
      <NewsletterSubscriptionReactForm
        {...newsletterSubscriptionWrapper.dataset}
        locale={options.locale}
        recaptcha={options.recaptcha}
      />,
      newsletterSubscriptionWrapper,
    )
  }

  showMore() {
    this.$el.find("#newsletter-archive .hidden").show()
    this.$el.find("#newsletter-archive .button").hide()
    this.$el.find("#newsletter-archive-show-more").hide()
  }

  // TODO: archive button should be entirely rendered through React, so we can delete
  //  the `app/views/subscriptions/_archive.html.erb` partial
  renderArchiveShowMoreButton() {
    const newsletterArchive = this.$el.find("#newsletter-archive-show-more")
    if (newsletterArchive.length > 0) {
      ReactDOM.render(
        <ThemeProvider theme={accentTheme()}>
          <Button variant="outlined" size="small" type="submit" onClick={this.showMore}>
            {i18n.t("newsletters.buttons.show_more")}
          </Button>
        </ThemeProvider>,
        newsletterArchive[0],
      )
    }
  }
}
