import i18n from "lib/i18n"
import { ajax as $ajax } from "jquery"
import Flash from "lib/flash"

const LOCK_USER_PATH = "/editorial/users/:id/lock.json"
const UNLOCK_USER_PATH = "/editorial/users/:id/unlock.json"

export default class SubmitForm {
  constructor({ locked, reason, userId }) {
    this.locked = locked
    this.reason = reason
    this.userId = userId
  }

  submit(callback = () => {}) {
    const reason = this.reason
    const actionUrl = this.locked ? UNLOCK_USER_PATH : LOCK_USER_PATH
    const toggleLockUrl = actionUrl.replace(":id", this.userId)

    $ajax({
      url: toggleLockUrl,
      type: "PUT",
      data: { reason },
      success: (response) => {
        const isLocked = response.locked
        const flashMessage = isLocked
          ? i18n.t("editorial_toggle_lock_user.messages.locked")
          : i18n.t("editorial_toggle_lock_user.messages.unlocked")

        Flash.now("notice", flashMessage)

        callback(isLocked)
      },
      error: () => {
        Flash.now("alert", i18n.t("editorial_toggle_lock_user.messages.error"))
      },
    })
  }
}
